import React, { useContext, useState, useEffect } from 'react';

import ModalContext from '../../../context/ModalContext.js';
import AuthContext from '../../../context/AuthContext.js';
import { CloseRounded } from '@mui/icons-material';
import { Button, MenuItem, Select, TextField } from '@mui/material';

const AssignAgentCouponModal = ({ modalDetails }) => {



    console.log(modalDetails);
    const modalCtx = useContext(ModalContext);
    const authCtx = useContext(AuthContext);

    const [userData, setUserData] = useState([]);
    const [selectedTab, setSelectedTab] = useState("network");

    const closeHandle = () => {
        modalCtx.closeModal();
    }

    const [agent, setAgent] = React.useState('');

  const handleChange = (event) => {
    setAgent(event.target.value );
  };

    return (
        <>
            <div className="create-devotional-container">
                <div className='devotional-header'>
                    <h1>Assign Agent to Register </h1>
                    {/* <CloseRounded className='close' onClick={() => closeHandle()} /> */}
                </div>
                <div className="body-container create-container agent-modal">

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={agent}
                        label="Agent"
                        onChange={handleChange}
                    >
                        <MenuItem value={10}>Aagent 1</MenuItem>
                        <MenuItem value={20}>Agent 2</MenuItem>
                        <MenuItem value={30}>Agent 3</MenuItem>
                    </Select>
                    

                </div>
                <div className="footer-devotional">
                    <Button style={{ color: "#555" }} onClick={() => closeHandle()}>Close</Button>
                    <Button style={{ color: "#555" }}>Assign</Button>
                </div>
            </div>
        </>
    )
}

export default AssignAgentCouponModal;
