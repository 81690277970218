import React, { useContext, useState, useEffect } from 'react';


import { CloseRounded } from '@mui/icons-material';
import { Button, MenuItem, Select, TextField } from '@mui/material';

import ModalContext from '../../../context/ModalContext.js';
import AuthContext from '../../../context/AuthContext.js';

import CustomButton from '../../../common/CustomButton';

const CreateCouponModal = ({ modalDetails }) => {

    const company = modalDetails.companyDetails;
    const acceptAction = modalDetails.acceptAction;

    console.log(modalDetails);
    const modalCtx = useContext(ModalContext);

    const closeHandle = () => {
        modalCtx.closeModal();
    }

    const [agent, setAgent] = React.useState('');

  const handleChange = (event) => {
    setAgent(event.target.value );
  };

    return (
        <>
            <div className="create-devotional-container create-coupon-modal">
                <div className='coupon-modal-header'>
                    <CloseRounded className='close' onClick={() => closeHandle()} />
                </div>
                <div className="body-container coupon-body-container">
                    <img src={company.logo}></img>
                    <h1>{company.companyDetails.company} </h1>
                    <span>Let's create a coupon!</span>
                    <CustomButton className="deliva-btn" onClick={acceptAction} >Accept</CustomButton>

                </div>
                
            </div>
        </>
    )
}

export default CreateCouponModal;
