import React, { useContext, useState, useEffect } from 'react';

import ModalContext from '../../../context/ModalContext.js';
import { Add, CloseRounded } from '@mui/icons-material';
import { CircularProgress, TextField } from '@mui/material';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import { toast } from 'react-toastify';
import AuthContext from '../../../context/AuthContext.js';
import { useCouponContext } from '../../../context/CouponContext.js';
import CustomButton from '../../../common/CustomButton.jsx';
import {
    setDefaults,
    fromLatLng,
  } from "react-geocode";

  setDefaults({
    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Your API key here.
    language: "en", // Default language for responses.
    region: "es", // Default region for responses.
  });


const AddLocationModal = ({ modalDetails }) => {

    const [loading, setLoading] = useState(true);
    const [addingLocation, setAddingLocations] = useState(false);

    const [selectedLocation, setSelectedLocation] = useState({});
    
    const {company, addHandler} = modalDetails;


    console.log(modalDetails);
    const modalCtx = useContext(ModalContext);
    const authCtx = useContext(AuthContext);

    const { couponState, updateCouponState } = useCouponContext();

    const closeHandle = () => {
        modalCtx.closeModal();
    }

    const [agent, setAgent] = React.useState('');

    const handleChange = (event) => {
        setAgent(event.target.value);
    };

    // Form Details

    const [locationDetails, setLocationDetails]= useState({
        title:"",
        address:"",
        phoneNumber:"",
        email:"",
        country:"",
        countryCode:"",
    })

    //Map Related Details

    const [latLng, setLatLng] = useState({
        lat: 0, lng: 0
    })

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    console.log(position.coords);
                    setLatLng({
                        lat: latitude,
                        lng: longitude
                    })
                    setLoading(false);
                   
                },
                (error) => {
                    console.error("Error getting current location:", error);
                }
            );
        } else {
            setLoading(false);
            console.error("Geolocation is not supported by this browser.");
        }
    };

    // Call the function to get the current location

    useEffect(() => {
        getCurrentLocation();
    }, [])

     
    useEffect(()=>{
        console.log("calling details")
        
      
    }, [latLng])

    const handleMapClick = (e) => {

        console.log(e);
        setSelectedLocation({
            lat: e.detail.latLng?.lat,
            lng: e.detail.latLng?.lng
        });

        fromLatLng(e.detail.latLng?.lat, e.detail.latLng?.lng)
            .then(({ results }) => {
                const { lat, lng } = results[0].geometry.location;
                console.log(results, "this is result");

                setLocationDetails({
                    ...locationDetails,
                    address: results[0].formatted_address,
                    country: results[0].address_components.find(item => item.types.includes("country")).long_name,
                    countryCode: results[0].address_components.find(item => item.types.includes("country")).short_name,                
                })

                console.log("curent location detais", locationDetails);
            })
            .catch(console.error);
    };

    const addLocationinLocalStorage = (newLocation)=>{
            if(newLocation != null){
                console.log("this is data of location after creating", newLocation);
                const v = {
                locations: [],
                checkedId: [],
                callToActionButton: [
                    { id: 1, name: 'Navigate Button', checked: false },
                    { id: 2, name: 'Webiste Button', checked: true },
                    { id: 3, name: 'Call Button', checked: true },
                    { id: 4, name: 'Email Button', checked: false },
                ]
                };

                let newList;
                const locationDetailsPage = couponState.locationDetailsPage;

                newList = [...couponState.locationDetailsPage.locations];

                newList.push(newLocation);

                var newCheckedList = [...couponState.locationDetailsPage.checkedId];

                newCheckedList.push(newLocation.id);

                var value = {
                    ...locationDetailsPage,
                    // checkedId: newCheckedList,
                    locations: newList,
                };
            
                updateCouponState({
                ...couponState, locationDetailsPage: value
                })
                setAddingLocations(false);

                modalCtx.closeModal();
            }
          
    }

    const addLocation = ()=>{
        if(locationDetails.address == "" || locationDetails.email==""|| locationDetails.phoneNumber=="" || locationDetails.title == ""){
            toast("Please fill all the details first");
        }else {
            setAddingLocations(true);
            var locationPayload = {
                companyId: company.id,
                latitude:selectedLocation.lat,
                longitude:selectedLocation.lng,
                country: locationDetails.countryCode,
                address:locationDetails.address, 
                email:locationDetails.email, 
                phone: locationDetails.phoneNumber, 
                title: locationDetails.title
              };

            try{
                fetch(process.env.REACT_APP_API_URI + `/v3/admin/company/${locationPayload.companyId}/location`, {
                    method:"POST",
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authCtx.token}` },
                    body:JSON.stringify(locationPayload)
                }).then((res)=> {return res.json()}).then((data) => {

                    if(data.status == 'success'){
                        var newLocationDetail= {
                            "id":data.id,
                            "title": locationPayload.title,
                            "address": locationPayload.address,
                            "country": locationPayload.country,
                            "latitude": locationPayload.latitude,
                            "longitude": locationPayload.longitude,
                        };
                        // addLocationinLocalStorage(newLocationDetail);
                        addHandler(newLocationDetail);
                    }
                })
            }catch(e){

            }
        }
    }



    return (
        <>
            <div className="create-devotional-container add-location-modal">

                <CloseRounded className='close' onClick={() => closeHandle()} />

                <div className="map-body-container">

                    <div className='map'>


                        {loading ? <div>
                            <CircularProgress />
                        </div> : <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                            <Map
                                defaultCenter={

                                    {
                                        lat: latLng.lat,
                                        lng: latLng.lng
                                    }

                                }
                                defaultZoom={11}
                                gestureHandling={'greedy'}
                                onClick={handleMapClick}

                            // disableDefaultUI={true}
                            >
                                {
                                    selectedLocation.lat && <>
                                        <Marker position={selectedLocation} /></>
                                }

                            </Map>


                        </APIProvider>}
                    </div>
                    <div className='textfield-container'>
                        <TextField 
                            value={locationDetails.address}
                            onChange={(e)=>{
                                setLocationDetails({...locationDetails, address: e.target.value})
                            }}
                            fullWidth
                            required
                            id="standard-required"
                            label="Address"
                            // defaultValue="Hello World"
                            placeholder='Enter your address'
                            variant="standard"
                        />
                        <TextField
                        value={locationDetails.title}
                        onChange={(e)=>{ setLocationDetails({...locationDetails, title : e.target.value}) }}
                            fullWidth
                            required
                            id="standard-required"
                            label="Title"
                            placeholder='Assign a title for your address'
                            // defaultValue="Hello World"
                            variant="standard"
                        />
                        <TextField
                        value={locationDetails.phoneNumber}
                        onChange={(e)=>{ setLocationDetails({...locationDetails, phoneNumber : e.target.value}) }}
                            fullWidth required
                            id="standard-required"
                            label="Phone"
                            placeholder='Phone'
                            // defaultValue="Hello World"
                            variant="standard"
                        />
                        <TextField
                        value={locationDetails.email}
                        onChange={(e)=>{ setLocationDetails({...locationDetails, email : e.target.value}) }}
                            required fullWidth
                            id="standard-required"
                            label="Email"
                            placeholder='Email'
                            // defaultValue="Hello World"
                            variant="standard"
                        />

                      <CustomButton disabled={addingLocation} className='roundedFull' onClick={()=> addLocation()} ><Add   /></CustomButton>  
                    </div>
                </div>

            </div>
        </>
    )
}

export default AddLocationModal;
