import React, { useEffect, useState, useContext } from 'react'

import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material';

import ModalContext from '../../context/ModalContext.js';
import DrawerContext from '../../context/DrawerContext.js';
import AuthContext from '../../context/AuthContext.js';

const Tags = () => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [tags, setTags] = useState([]);

  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {
    getAllTags();
  }, [drawerCtx, modalCtx]);


  const getAllTags = () => {

    fetch(process.env.REACT_APP_API_URI + '/v3/tags', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        // console.log(response);
        return response.json();
      }).then(data => {
        console.log('Success:', data);
        if (data.status == 'success') {
          setTags(data?.data)
        }
      }).catch(err => console.error(err));

  }

  const openDeleteTagModal = (id) => {
    modalCtx.setDetails('delete-tag', { id });
    modalCtx.openModal();
  }

  return (
    <div className="tags-page">

      <header className="template-header" >
        {/* <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Topics &nbsp; {loadingBlogs ? <CircularProgress size={22} /> : null}</h1> */}
        <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Tags</h1>
        {/* <div>
          <button className='btn-primary' data-aos="fade-right" data-aos-delay="400" onClick={() => { toggleDrawerHandler('create-blog', null) }}>Create New</button>
        </div> */}
      </header>

      <section className='template-section' >
        <div className='tags-table'>
          <TableContainer className='tags-table' component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ position: "sticky", top: "0", background: "#FFF", zIndex: "100" }} >
                <TableRow>
                  <TableCell align="left" style={{ fontWeight: 700, fontSize: '16px' }}>Tag</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  tags?.length > 0
                    ? tags?.map((tag, index) => (
                      <TableRow style={{ background: '#fff', boxShadow: "10px 3px 10px rgba(0, 0, 0, 0.0392156863)", borderRadius: "8px" }} key={tag.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>
                          <span style={{ fontWeight: 500, fontSize: '16px' }} >{tag.title}</span>
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">
                          <Edit style={{ cursor: 'pointer' }} onClick={() => toggleDrawerHandler('edit-tag', tag)} />
                          <Delete style={{ marginLeft: '1rem', cursor: 'pointer' }} onClick={() => openDeleteTagModal(tag.id)} />
                        </TableCell>
                      </TableRow>
                    ))
                    : null
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </section>
    </div >
  )
}

export default Tags;
