//react
import React, { useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";

//mui
import { Button, TextField, Switch, FormGroup, FormControlLabel, CircularProgress, Box, Tabs, Tab, Typography, TextareaAutosize } from '@mui/material';


//context
import AuthContext from '../../../context/AuthContext';
import DrawerContext from '../../../context/DrawerContext';
import ModalContext from '../../../context/ModalContext';

//component
import TextInput from '../../../common/TextInput'



const ManageCompanyDrawer = ({drawerDetails}) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  console.log(drawerDetails, "this is data from model");

  const [loading, setLoading] = useState(false)


  const [createBlogObj, setCreateBlogObj] = useState({ snippet: "", title: "", post: "", pageTitle: "", metaDescription: "", metaKeywords: "", isVideo: "no", videoUrl: "" });
  const [companyObj, setCompanyObj] = useState({
    id: drawerDetails.id,
    logo: drawerDetails.logo,
    companyDetails: {
        id: drawerDetails.companyDetails.id,
        orderNo: drawerDetails.companyDetails.orderNo,
        company: drawerDetails.companyDetails.company,
        contact: drawerDetails.companyDetails.contact,
        email: drawerDetails.companyDetails.email,
        mobile: drawerDetails.companyDetails.mobile,
        colour: drawerDetails.companyDetails.colour,
        status: drawerDetails.companyDetails.status,
        slug: drawerDetails.companyDetails.slug,
        keywords: drawerDetails.companyDetails.keywords,
        tasksCompleted: drawerDetails.companyDetails.tasksCompleted,
        lastTaskCompletedDate: drawerDetails.companyDetails.lastTaskCompletedDate,
    },
    contactDetails: {
        contact: drawerDetails.contactDetails.contact,
        email: drawerDetails.contactDetails.email,
        mobile: drawerDetails.contactDetails.mobile,
        status: drawerDetails.contactDetails.status,
    },
    locations: [],
    coupons: 1,
    agents: [],
    globalSupplier: true,
    countries: null
});

  const submitCreateBlog = () => {

    setLoading(true)

    const { snippet, title, post, pageTitle, metaDescription, metaKeywords, isVideo, videoUrl } = createBlogObj;

    try {

      fetch(process.env.REACT_APP_API_URI + '/v3/admin/blog', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ snippet, title, post, pageTitle, metaDescription, metaKeywords, isVideo, videoUrl })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status === "success") {
          getAllBlogs(data.id)
        }
      })

    } catch (error) {
      console.log('error : ', error)
    }
  }

  const getAllBlogs = (id) => {

    try {

      fetch(`${process.env.REACT_APP_API_URI}/v3/blogs/${id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status === 'success') {
            setLoading(false)
            openUploadImageModal(id, data.data[0])
            drawerCtx.setDetails(null, data.data[0]);
            drawerCtx.closeDrawer();
          }
        }).catch(err => {
          console.error(err)
        });

    } catch (error) {
      console.log('error : ', error)
    }
  }

  const handleVideoBoolean = (e) => {
    if (e) {
      setCreateBlogObj((prev) => ({ ...prev, isVideo: 'yes' }))
    } else {
      setCreateBlogObj((prev) => ({ ...prev, isVideo: 'no' }))
    }
  }

  const openUploadImageModal = (id, blog) => {
    modalCtx.setDetails('upload-fixed-image', { aspectRatio: 1.6, origin: 'blog', id: id, blog: blog });
    modalCtx.openModal();
  }

  const [value, setValue] = useState('contacts');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="drawer-container">
      <div className="drawer">
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Manage Locations</h1>
        </header>

        <section style={{paddingTop:'2rem', display:'flex', flexDirection:'column' , gap:'2rem' }}>

          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab value="contacts" label="Contacts" />
            <Tab value="branding" label="Branding" />
            <Tab value="notes" label="Notes" />
          </Tabs>

          {value == "contacts" ? <>
          
          <div className=' contact-container'>

       

        <TextField name="title" label="Company Name"  />
        <TextField name="title" label="Slug"   />
        <TextField name="title" label="Contact Person"   />
        <TextField name="title" label="Email Address"   />
        <TextField name="title" label="Mobile Number"   />
        <TextField name="title" label="Description"   />

        <div className="margin-top button-container">
          <Button variant='outlined'  className='btn-primary' >Cancel</Button>
          <Button variant='contained'  className='btn-primary' >Save Changes</Button>
            {/* <button disabled={!createBlogObj?.title.length} className='btn-primary' onClick={(e) => submitCreateBlog()}>{loading ? <CircularProgress size={22} style={{color: "#fff"}} /> : "Save"}</button> */}
          </div>

          </div>
          </>: value == "branding" ? <>
          
          <div className=' contact-container'>

            <div className='image-container'>

              <img src={drawerDetails.logo}></img>

            </div>
            <label>(note this will be used as a circle logo in all instances. Please upload a square image 100x100)</label>
            <input type='file' className='image-upload'></input>

        <TextField name="title" label="Colour"   />
        

        <div className="margin-top button-container">
          <Button variant='outlined'  className='btn-primary' >Cancel</Button>
          <Button variant='contained'  className='btn-primary' >Update</Button>
            {/* <button disabled={!createBlogObj?.title.length} className='btn-primary' onClick={(e) => submitCreateBlog()}>{loading ? <CircularProgress size={22} style={{color: "#fff"}} /> : "Save"}</button> */}
          </div>

          </div>
          
          </>: <>Notes</>}
        
        {/* <div className="double-column-form aligned-left margin-top">
          <Button variant='outlined' disabled={!createBlogObj?.title.length} className='btn-primary' >Cancel</Button>
          <Button variant='outlined' disabled={!createBlogObj?.title.length} className='btn-primary' >Save Changes</Button>
          </div> */}
        </section>
      </div>
    </div>
  )
}

export default ManageCompanyDrawer;

