import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { Typography, Box, TextField, Button } from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function CreateNetworkDrawer(props) {

  let translation;
  let fileType;

  const [selectedTab, setSelectedTab] = React.useState(0);
  const [disabled, setDisabled] = useState(true);
  const [isZip, setIsZip] = useState(true);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const updateTranslation = (event) => {
    translation = event.target.value;
    checkImportBtn()
  }

  const updateFileType = (event) => {
    console.log(event);
    fileType = event.target.value;
    checkImportBtn()
  }

  const checkImportBtn = () => {
    if (translation != "" && fileType != "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  const onFileChangeCapture = (event) => {
    console.log(isZip);
    /*Selected files data can be collected here.*/
    console.log(event.target.files[0].type);
    if (isZip.isTrue && event.target.files[0].type != "application/x-zip-compressed") {
      alert("You did not upload a ZIP file... Please try again");
    } else if (isZip.isTrue && event.target.files[0].type == "application/x-zip-compressed") {
      console.log("Upload zip file");
    } else if (!isZip.isTrue && event.target.files[0].type != "application/xml") {
      console.log("You did not upload an XML file... Please try again");
    } else if (!isZip.isTrue && event.target.files[0].type == "application/xml") {
      console.log("You did not upload an XML file... Please try again");
    } else {
      alert("Something went wrong");
    }
  };

  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Create Network</h1>
        </header>

        <section>

          <div className="double-column-form">
            <TextField label="Title" value={translation} onChange={(e) => { updateTranslation(e) }} placeholder="Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
          </div>

          <div className="double-column-form aligned-right margin-top">

            <Button variant="contained" component="label" disabled={disabled}>
              Create Network
              <input type="file" onChangeCapture={onFileChangeCapture} hidden />
            </Button>
          </div>

        </section>

      </div>
    </div>
  );

}
export default CreateNetworkDrawer;

