import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// context
import AuthContext from '../../context/AuthContext.js';

// assets
import OverviewIcon from '../../assets/img/sidebar/overview_icon.svg'
import AdminIcon from '../../assets/img/sidebar/administrator_icon.svg'
import CategoriesIcon from '../../assets/img/sidebar/categories_icon.svg'
import CompaniesIcon from '../../assets/img/sidebar/companies_icon.svg'
import CouponIcon from '../../assets/img/sidebar/coupon_icon.svg'
import LogoutIcon from '../../assets/img/sidebar/logout_icon.svg'
import SimIcon from '../../assets/img/sidebar/simulator_icon.svg'
import UserIcon from '../../assets/img/sidebar/user_icon.svg'
import ResourcesIcon from '../../assets/img/sidebar/resource_icon.svg'
import AgentIcon from '../../assets/img/sidebar/agents_icon.svg'
import FinderIcon from '../../assets/img/sidebar/finder_icon.svg'
import PriceIcon from '../../assets/img/sidebar/pricing_icon.svg'
import ErIcon from '../../assets/img/sidebar/er_services_icon.svg'
import OverviewIconDark from '../../assets/img/sidebar/overview_icon_color.svg'
import AdminIconDark from '../../assets/img/sidebar/administrator_icon_color.svg'
import CategoriesIconDark from '../../assets/img/sidebar/categories_icon_color.svg'
import CompaniesIconDark from '../../assets/img/sidebar/companies_icon_color.svg'
import CouponIconDark from '../../assets/img/sidebar/coupons_icon_color.svg'
import SimIconDark from '../../assets/img/sidebar/simulator_icon_color.svg'
import UserIconDark from '../../assets/img/sidebar/user_icon_color.svg'
import ResourcesIconDark from '../../assets/img/sidebar/resource_icon_color.svg'
import AgentIconDark from '../../assets/img/sidebar/agents_icon_color.svg'
import FinderIconDark from '../../assets/img/sidebar/finder_icon_color.svg'
import PriceIconDark from '../../assets/img/sidebar/pricing_icon_color.svg'
import ErIconDark from '../../assets/img/sidebar/er_services_icon_color.svg'
import Logo from '../../assets/img/deliva_icon.svg';

// assets MIU
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded, AddRounded } from '@mui/icons-material';
import { Button } from '@mui/material';


const Sidebar = ({ setSidebarOpen }) => {

  //mics
  const noSideBarRoutes = ["/login", "/register"];
  const { pathname } = useLocation();
  let navigate = useNavigate();

  //context
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;

  //states
  const [activeItem, setActiveItem] = useState('overview')
  //boolean states
  const [sidebarClosed, setSidebarClosed] = useState(false)

  //effects

  useEffect(() => {

    if (pathname.slice(1) === "") {
      setActiveItem("overview")
    } else {
      setActiveItem(pathname.slice(1))
    }

  }, [])



  //functions

  const handleItem = (path, item) => {
    navigate(path);
    setActiveItem(item)
  }

  const handleSideBar = () => {
    if (sidebarClosed) {
      setSidebarClosed(false)
      setSidebarOpen(true)
    } else {
      setSidebarClosed(true)
      setSidebarOpen(false)
    }
  }


  if (noSideBarRoutes.some((item) => pathname.includes(item))) return null;

  return (
    <div className="sidebar" style={sidebarClosed ? { width: "65px", minWidth: "65px" } : null}>
      <div className="user-info">
        <div className="company-logo"><img src={Logo} alt="logo" /></div>
        <nav className='user-nav' style={sidebarClosed ? { display: 'none' } : null}>
          <div className="user-name">
            <span className='username'>Rushikesh Godse</span>
            <span className='user-email'>rushikesh@studio137.co.za</span>
          </div>
        </nav>
      </div>
      <div className="manage-heading">
        {sidebarClosed ? null : <span>Manage</span>}
        {sidebarClosed ?
          <KeyboardArrowRightRounded className='back-icon' onClick={() => handleSideBar()} />
          :
          <KeyboardArrowLeftRounded className='back-icon' onClick={() => handleSideBar()} />
        }
      </div>
      <div className="sidebar-content">
        <div className="manage-container" style={sidebarClosed ? { paddingLeft: "0px" } : null}>
          <div className="manage">

            <div className={activeItem == 'overview' ? 'sidebar-item active-item' : 'sidebar-item'} onClick={() => handleItem('/overview', 'overview')}>
              <img src={activeItem == 'overview' ? OverviewIconDark : OverviewIcon} alt="item icon" className="item-icon item-icon-active" />
              {sidebarClosed ? null : <span className="item-title">Overview</span>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

            <div className={activeItem == 'resource' ? 'sidebar-item active-item' : 'sidebar-item'} onClick={() => handleItem('/resource', 'resource')}>
              <img src={activeItem == 'resource' ? ResourcesIconDark : ResourcesIcon} alt="item icon" className="item-icon" />
              {sidebarClosed ? null : <span className="item-title">Resources</span>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

            <div className={activeItem == 'agents' ? 'sidebar-item active-item' : 'sidebar-item'} onClick={() => handleItem('/agents', 'agents')}>
              <img src={activeItem == 'agents' ? AgentIconDark : AgentIcon} alt="item icon" className="item-icon" />
              {sidebarClosed ? null : <span className="item-title">Agents</span>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

            <div className={activeItem == 'users' ? 'sidebar-item active-item' : 'sidebar-item'} onClick={() => handleItem('/users', 'users')}>
              <img src={activeItem == 'users' ? UserIconDark : UserIcon} alt="item icon" className="item-icon" />
              {sidebarClosed ? null : <span className="item-title">Users</span>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

            <div className={activeItem == 'simulator' ? 'sidebar-item active-item' : 'sidebar-item'} onClick={() => handleItem('/simulator', 'simulator')}>
              <img src={activeItem == 'simulator' ? SimIconDark : SimIcon} alt="item icon" className="item-icon" />
              {sidebarClosed ? null : <span className="item-title">Simulator</span>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

            <div className={activeItem == 'business-finder' ? 'sidebar-item active-item' : 'sidebar-item'} onClick={() => handleItem('/business-finder', 'business-finder')}>
              <img src={activeItem == 'business-finder' ? FinderIconDark : FinderIcon} alt="item icon" className="item-icon" />
              {sidebarClosed ? null : <span className="item-title">Business finder</span>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

            <div className={activeItem == 'pricing-table' ? 'sidebar-item active-item' : 'sidebar-item'} onClick={() => handleItem('/pricing-table', 'pricing-table')}>

              <img src={activeItem == 'pricing-table' ? PriceIconDark : PriceIcon} alt="item icon" className="item-icon" />

              {sidebarClosed ? null : <span className="item-title">Pricing Table</span>}

              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

          </div>
        </div>

        <div className="build-container" style={sidebarClosed ? { paddingLeft: "0px" } : null}>
          {sidebarClosed ? null : <div className="build-heading">Build</div>}
          <div className="build">

            {/* <div className={activeItem == 'create-coupon' ? ` new-coupon-btn active ${sidebarClosed ? "sidebar-closed-coupon-btn" : null}` : `new-coupon-btn ${sidebarClosed ? "sidebar-closed-coupon-btn" : null}`} onClick={() => handleItem('/create-coupons', 'create-coupon')}>
              <div className='new-btn'><AddRounded /> <span className='item-title'> {sidebarClosed ? null : "New Coupon"}</span></div>
              {activeItem == 'create-coupon' && <div className='background-div'></div>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div> */}

            <div className={activeItem == 'create-coupon' ? 'new-coupon new-coupon-active' : 'new-coupon'} onClick={() => handleItem('/create-coupons', 'create-coupon')}>
              <div className='new-btn' style={sidebarClosed ? { width: '35px', padding: '0' } : null} ><AddRounded /> <span className='item-title'> {sidebarClosed ? null : "New Coupon"}</span></div>
              {activeItem == 'create-coupon' && <div className='background-div'></div>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>


            <div className={activeItem == 'companies' ? 'sidebar-item active-item' : 'sidebar-item'} onClick={() => handleItem('/companies', 'companies')}>
              <img src={activeItem == 'companies' ? CompaniesIconDark : CompaniesIcon} alt="item icon" className="item-icon" />
              {sidebarClosed ? null : <span className="item-title">Companies</span>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

            <div className={activeItem == 'coupons' ? 'sidebar-item active-item' : 'sidebar-item'} onClick={() => handleItem('/coupons', 'coupons')}>
              <img src={activeItem == 'coupons' ? CouponIconDark : CouponIcon} alt="item icon" className="item-icon" />
              {sidebarClosed ? null : <span className="item-title">Coupons</span>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

            <div className={activeItem == 'emergency-services' ? 'sidebar-item active-item' : 'sidebar-item'} onClick={() => handleItem('/emergency-services', 'emergency-services')}>
              <img src={activeItem == 'emergency-services' ? ErIconDark : ErIcon} alt="item icon" className="item-icon" />
              {sidebarClosed ? null : <span className="item-title">ER Services</span>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

            <div className={activeItem == 'categories' ? 'sidebar-item active-item' : 'sidebar-item'} onClick={() => handleItem('/categories', 'categories')}>
              <img src={activeItem == 'categories' ? CategoriesIconDark : CategoriesIcon} alt="item icon" className="item-icon" />
              {sidebarClosed ? null : <span className="item-title">Categories</span>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

            <div className={activeItem == 'blogs' ? 'sidebar-item active-item' : 'sidebar-item'} onClick={() => handleItem('/blogs', 'blogs')}>
              <img src={activeItem == 'blogs' ? CategoriesIconDark : CategoriesIcon} alt="item icon" className="item-icon" />
              {sidebarClosed ? null : <span className="item-title">Blogs</span>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

            <div className={activeItem == 'tags' ? 'sidebar-item active-item' : 'sidebar-item'} onClick={() => handleItem('/tags', 'tags')}>
              <img src={activeItem == 'tags' ? CategoriesIconDark : CategoriesIcon} alt="item icon" className="item-icon" />
              {sidebarClosed ? null : <span className="item-title">Tags</span>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

            <div className={activeItem == 'topics' ? 'sidebar-item active-item' : 'sidebar-item'} onClick={() => handleItem('/topics', 'topics')}>
              <img src={activeItem == 'topics' ? CategoriesIconDark : CategoriesIcon} alt="item icon" className="item-icon" />
              {sidebarClosed ? null : <span className="item-title">Topics</span>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

          </div>

        </div>

        <div className="admin-container" style={sidebarClosed ? { paddingLeft: "0px" } : null}>
          <div className="admin">

            <div className={activeItem == 'administrators' ? 'sidebar-item active-item' : 'sidebar-item'} onClick={() => handleItem('/administrators', 'administrators')}>
              <img src={activeItem == 'administrators' ? AdminIconDark : AdminIcon} alt="item icon" className="item-icon" />
              {sidebarClosed ? null : <span className="item-title">Administrators</span>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

            <div className='sidebar-item' onClick={() => authCtx.logout()}>
              <img src={LogoutIcon} alt="item icon" className="item-icon" />
              {sidebarClosed ? null : <span className="item-title">Logout</span>}
              <div className="corner-radius-helper-1"></div>
              <div className="corner-radius-helper-2"></div>
            </div>

          </div>

        </div>
      </div>
    </div>
  );
}
export default Sidebar;