import React, { useContext, useEffect, useState } from 'react'
import { Close } from '@mui/icons-material';
import { Avatar, Card, CircularProgress, TextField } from '@mui/material';
import CustomButton from '../../../common/CustomButton';
import AuthContext from '../../../context/AuthContext';
import ModalContext from '../../../context/ModalContext';
import addCompnayIcon from '../../../assets/img/company/add-company.png';

export default function ChooseCompany({ selectedCompany, selectCompany, moveToNextStep }) {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [companiesData, setCompaniesData] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  
  useEffect(() => {
    const filteredItems = companiesData?.filter((company) => company?.companyDetails?.company.toLowerCase().includes(searchValue.toLowerCase()));
    setFilteredCompanies(filteredItems);
  }, [searchValue, companiesData]);

  useEffect(() => {
    getAllCompanies();
  }, [])

  const getAllCompanies = () => {
    setLoading(true);
    try {
      fetch(`${process.env.REACT_APP_API_URI}/v3/admin/companies`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          setLoading(false);
          setCompaniesData(data?.data);
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  const moveNext = () => {

    moveToNextStep(1);
    modalCtx.closeModal();

    // Save to Coupon Context

    // Change Current Step to 1

    // Close Modal

    // updateCouponState({
    //   ...couponState, currentStep: 1
    // })
    // modalCtx.closeModal();
  }

  return (
    <div className='choose-company-section-v2'>
      <div style={{ display: 'flex', flexDirection: "column", gap: '1rem', padding: '50px', width: "100%", maxWidth: '700px' }}>
        <Card elevation={5} sx={{ backgroundColor: "white" }} className='selectedCompany-div'>
          {
            selectedCompany == null
              ? <TextField sx={{ border: "none" }} value={searchValue} disabled={loading} onChange={(e) => setSearchValue(e.target.value)} fullWidth placeholder='Search Company' />
              : selectedCompany &&
              <div className='selected-company-item'>
                {selectedCompany.companyDetails.company} <Close onClick={() => selectCompany(null)} />
              </div>
          }
        </Card>

        <Card className='search-company-card' elevation={5} sx={{ backgroundColor: "white", height: '600px', width: '100%' }}>
          {loading
            ?
            <div className='loading-container'><CircularProgress /></div>
            : <>
              <div className='companies-item-div-container'>
                {
                  filteredCompanies?.map((company, index) => (
                    <div onClick={() => { selectCompany(company); setSearchValue('') }} className='companies-item-div' key={company?.id}>
                      <Avatar sx={{ width: 50, height: 50, backgroundColor: "#2b1de8", color: "white" }} src={company.logo}>{company?.title?.charAt(0)}</Avatar>
                      <span>{company?.companyDetails?.company}</span>
                    </div>
                  ))
                }
              </div>
              <div className='confirm-div'>
                <CustomButton onClick={() => handleModal('create-coupon-accept', { companyDetails: selectedCompany, acceptAction: moveNext })} disabled={selectedCompany == null} className='search-company-btn'>Confirm</CustomButton>
              </div>
            </>
          }
        </Card>
      </div>
      <div className='seperator'>
        <div className='line'></div>
        <span className='text'>Or</span>

      </div>
      <div className='add-coupons-right'>

        <div className='add-card'>
          <div className='add-a-company'>
            <img src={addCompnayIcon} alt="" />
            <span className='text'>Don't you have a registered company?
            </span>
            <CustomButton variant='outlined'>Add Company</CustomButton>
          </div>
        </div>
      </div>
    </div>
  )
}
