import React, { useRef, useContext, useState, useEffect } from 'react';
import { Alert, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Snackbar, TextField, Switch, FormGroup, FormControlLabel, CircularProgress, SnackbarContent } from '@mui/material';
import AuthContext from '../../../context/AuthContext';
import DrawerContext from '../../../context/DrawerContext';

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import { AddRounded, ArrowBackIos, ArrowBackRounded, ArrowForwardIosRounded, ArrowForwardRounded, CloseRounded, HighlightOffRounded, KeyboardArrowDownRounded, KeyboardBackspace, PublishRounded, Remove, ReplayRounded, Upload } from '@mui/icons-material';
import GetImage from '../../../common/GetImage';

import placeholder from '../../../assets/img/placeholder.png';
import backbutton from '../../../assets/img/drawer/back.svg';

import Flower1 from '../../../assets/img/blog/edit/blog-edit-flower-1.png'
import Flower2 from '../../../assets/img/blog/edit/blog-edit-flower-2.png'

import Logo from '../../../assets/img/deliva_icon.svg';

import ModalContext from '../../../context/ModalContext';

const EditBlogDrawer = ({ drawerDetails }) => {

  //clg
  console.log(drawerDetails)

  //refs
  const titleRef = useRef(null);

  //context
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);

  //states
  const [open, setOpen] = useState(false);
  const [metaTagsOpen, setMetaTagsOpen] = useState(false);
  const [editorToolOpen, setEditorToolOpen] = useState(true);
  const [tagsTopicBarOpen, setTagsTopicBarOpen] = useState(true);
  const [blogVideoToggle, setBlogVideoToggle] = useState(false);

  // updating status states 
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [updatingStatusId, setUpdatingStatusId] = useState();
  const [savingChanges, setSavingChanges] = useState(false);

  const [blog, setBlog] = useState(drawerDetails);
  const [createBlogObj, setCreateBlogObj] = useState({
    snippet: blog?.snippet || "",
    title: blog?.title || "",
    post: blog?.post || "",
    slug: blog?.slug || blog?.id,
    pageTitle: blog?.pageTitle || "",
    metaDescription: blog?.metaDescription || "",
    metaKeywords: blog?.metaKeywords || "",
    isVideo: blog?.isVideo || "no",
    videoUrl: blog?.videoUrl || "",
    customUrl: blog?.customUrl || blog?.id,
  });
  const [authors, setAuthors] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [author, setAuthor] = useState('');
  const [blogUrl, setBlogUrl] = useState(blog?.id);
  const [slug, setSlug] = useState(blog?.id);
  const [updateBlogStatus, setUpdateBlogStatus] = useState(false);
  const [statusClass, setStatusClass] = useState(() => {
    if (blog?.status === 'published') {
      return 'status-button published';
    } else {
      return 'status-button';
    }
  });
  const [editor, setEditor] = useState('myeditor disable');

  const quillRef = useRef();


  //effect
  useEffect(() => {
    submitEditBlog()
  }, [modalCtx])

  useEffect(() => {

    if (titleRef && titleRef.current) {
      titleRef.current.innerText = blog?.title;
    }

  }, [blog]);

  const handleTitleInput = (e) => {
    setCreateBlogObj((prev) => ({ ...prev, title: e.target.innerText }))
  }

  //functions
  const submitEditBlog = () => {

    setSavingChanges(true);


    if (authCtx.isLoggedIn) {
      const { title, snippet, post, slug, pageTitle, metaDescription, metaKeywords, isVideo, videoUrl } = createBlogObj;
      fetch(process.env.REACT_APP_API_URI + '/v3/admin/blog/' + blog?.id, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({
          title,
          snippet,
          post,
          slug,
          pageTitle,
          metaDescription,
          metaKeywords,
          isVideo,
          videoUrl
        })
      }).then(response => {
        console.log("getting response");
        return response.json();
      }).then(data => {
        setSavingChanges(false);
        if (data.status === "success") {
          updateURL(blog?.id, slug);
          console.log(blogUrl);
        } else {
          console.error("Error while editing blog?...");
        }
      }).catch((err) => console.error("Error while editing blog?...", err));
    }
  }

  const updateURL = async (blogId, slug) => {

    // setUpdatingStatus(true)
    setUpdatingStatusId(blogId)

    try {

      fetch(process.env.REACT_APP_API_URI + '/v2/blogs/' + blogId + '/updateSlug', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ slug })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status === 'success') {
            getBlog();
          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  const closeDrawer = () => {
    console.log(blog?.image);
    drawerCtx.closeDrawer();

  }

  const getBlog = () => {
    fetch(process.env.REACT_APP_API_URI + '/v2/blogs/' + blog?.id, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status === 'success') {
          console.log("blogs ", data.data)
          setBlog(data.data[0]);
          setOpen(true);

          updateStatusClass();

          //here we will set the details
        }
      }).catch(err => {
        console.error(err)
        // setUpdatingStatus(false)

      });
  }
  const updateStatusClass = () => {
    if (blog?.status === 'published') {
      setStatusClass('status-button published');
    } else {
      setStatusClass('status-button');
    }
  }

  const openUploadImageModal = (id) => {
    modalCtx.setDetails('upload-image', { origin: 'blog', id: id });
    modalCtx.openModal();
  }


  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }


  const updateStatus = async (blogId, status) => {



    try {

      fetch(process.env.REACT_APP_API_URI + '/v2/blogs/' + blogId + '/updateStatus', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ status })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status === 'success') {
            // getAllBlogs()
            console.log("updated");
          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const removeSingleTopic = (indexToRemove) => {
    blog?.topics.splice(indexToRemove, 1);
    console.log(blog);

  }
  //quill editor



  //functions
  const submitEditAuthor = (id) => {

    if (authCtx.isLoggedIn) {
      fetch(process.env.REACT_APP_API_URI + '/v2/blogs/author', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({
          id: blog?.id,
          authorId: id
        })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status === "success") {
        } else {
          console.error("Error while editing blog?...");
        }
      }).catch((err) => console.error("Error while editing blog?...", err));
    }
  }

  const getAllAuthors = () => {

    fetch(process.env.REACT_APP_API_URI + '/v2/authors', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        console.log(response.json);
      }).then(data => {
        if (data.status === 'success') {
          setAuthors(data?.data)
        }
      }).catch(err => console.error(err));

  }

  const DeleteAuthor = async (devoId, id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v2/blogs/${devoId}/author/${id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const topicsResponse = await response.json();
      if (topicsResponse.status === 'success') {
        submitEditBlog()
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleChangeAuthor = (event) => {
    setAuthor(event.target.value);
  }

  const handleSelectAuthor = (author) => {
    if (!selectedAuthors.some(el => el.id === author.id)) {
      submitEditAuthor(author.id)
      // setSelectedAuthors(prev => [...prev, author]);
    } else {
      return
    }
  }

  const handleFilterArray = (id) => {

    DeleteAuthor(blog?.id, id)
    const newArr = selectedAuthors.filter((arr) => {
      return arr.id !== id
    })
    setSelectedAuthors(newArr)
  }

  const changeEditorStatus = (e) => {
    if (e) {
      const quill = quillRef.current.getEditor();
      console.log(quill.getSelection());
      setEditor("myeditor active")
    } else {
      const quill = quillRef.current.getEditor();

      console.log(quill.getSelection());
      // quill.setSelection('');

      setEditor(" myeditor disable")
    }
  }

  const DeleteTopic = async (devoId, id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v2/blogs/${devoId}/topic/${id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const topicsResponse = await response.json();
      if (topicsResponse.status === 'success') {
        submitEditBlog()
      }
    } catch (err) {
      console.log(err);
    }
  }


  const DeleteTag = async (devoId, id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/v2/blogs/${devoId}/tag/${id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
        })

      const topicsResponse = await response.json();
      if (topicsResponse.status === 'success') {
        submitEditBlog()
      }
    } catch (err) {
      console.log(err);
    }
  }

  const openPublishModal = (blog) => {
    modalCtx.setDetails('confirm-publish-blog', { blog });
    modalCtx.openModal();
    getBlog();
  }

  const updateStatusBlog = async (blogId, status) => {

    setUpdatingStatus(true)
    setUpdatingStatusId(blogId)

    try {

      fetch(process.env.REACT_APP_API_URI + '/v2/blogs/' + blogId + '/updateStatus', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ status })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          setUpdatingStatus(false)
          if (data.status === 'success') {
            submitEditBlog()

          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  const handleVideoUrl = (url) => {
    let finalUrl
    let embedId

    if (url.includes('youtu.be' || 'youtube')) {
      embedId = url.split('/')
      finalUrl = `https://www.youtube.com/embed/${embedId[embedId.length - 1]}`
    }

    return finalUrl
  }


  const handleVideoBoolean = (e) => {
    if (e) {
      setCreateBlogObj((prev) => ({ ...prev, isVideo: 'yes' }))
    } else {
      setCreateBlogObj((prev) => ({ ...prev, isVideo: 'no' }))
    }
  }

  function showRegDate(unFormattedDate) {

    const date = new Date(unFormattedDate);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };

    return date.toLocaleDateString('en-US', options);
  }

  const handleMetaTagsBoolean = (e) => {
    if (e) {
      setMetaTagsOpen(true)
    } else {
      setMetaTagsOpen(false)
    }
  }

  const handleEditorToolBoolean = () => {
    if (editorToolOpen) {
      setEditorToolOpen(false)
    } else {
      setEditorToolOpen(true)
    }
  }

  const handleTagsTopicBoolean = () => {
    if (tagsTopicBarOpen) {
      setTagsTopicBarOpen(false)
    } else {
      setTagsTopicBarOpen(true)
    }
  }

  return (

    <div className="edit-blog-container">
      <div className="drawer blog-drawer-container">
        <div className="blog-drawer" style={editorToolOpen ? { gridTemplateRows: '160px 1fr 100px' } : { gridTemplateRows: '115px 1fr 100px' }}>

          <div className="blog-drawer-header">
            <header>
              <h1 data-aos="fade-right" data-aos-delay="500">
                < img className='back-button tool-button' onClick={(e) => {
                  closeDrawer();
                }} src={backbutton} alt='' />
                Edit Blog - {createBlogObj.title}</h1>
            </header>

            <div className="blog-webpage-clone">
              <div className="webpage-clone">
                <div className="webpage-link">
                  <ArrowBackRounded style={{ color: '#aaa' }} className='link-icon' />
                  <ArrowForwardRounded style={{ color: '#aaa' }} className='link-icon' />
                  <ReplayRounded style={{ color: '#aaa' }} className='link-icon' />
                  <div className="link-container">
                    <span>kingdomempowered.com/blogs/</span>
                    <input type="text" name="slug" placeholder={createBlogObj.slug} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} />
                  </div>
                </div>
                <div className="title-tab">
                  <img src={Logo} alt="logo" />
                  <span>{createBlogObj.title}</span>
                  <CloseRounded style={{ color: '#aaa' }} />
                </div>

                <div className='action-buttons-div'>
                  <FormGroup>
                    <FormControlLabel label="Show Meta Tags" control={<Switch />} onChange={e => handleMetaTagsBoolean(e.target.checked)} />
                  </FormGroup>
                  <button className='btn-primary' variant="contained" component="label" disabled={false}
                    onClick={(e) => submitEditBlog()}> {savingChanges ? <CircularProgress style={{ color: "white" }} size={20} /> : "Save Changes"}
                  </button>
                  <button className='btn-primary-border' variant="outlined" component="label" disabled={false}
                    onClick={(e) => closeDrawer()}>Cancel
                  </button>
                </div>

                <div className="meta-tags-container" style={metaTagsOpen ? null : { display: 'none' }}>
                  <TextField style={{ margin: "10px auto 15px" }} name="pageTitle" label="Page Title" value={createBlogObj.pageTitle} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Page Title" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
                  <TextField style={{ margin: "10px auto 15px" }} name="metaDescription" label="Meta Description" value={createBlogObj.metaDescription} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Meta Description" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
                  <TextField style={{ margin: "10px auto 15px" }} name="metaKeywords" label="Meta Keywords" value={createBlogObj.metaKeywords} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Meta Keywords" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required />
                  {/* <FormGroup>
                    <FormControlLabel label="Video" control={<Switch checked={createBlogObj.isVideo === 'yes'} />} onChange={e => handleVideoBoolean(e.target.checked)} />
                  </FormGroup>
                  <TextField disabled={createBlogObj.isVideo !== 'yes'} style={{ margin: "10px auto 15px" }} name="videoUrl" label="Video Url" value={createBlogObj.videoUrl} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))} placeholder="Video Url" variant="outlined" InputLabelProps={{ shrink: true }} fullWidth required /> */}
                </div>

              </div>
            </div>
            <div className="editor-toolbar-container">
              <div className="editor-toolbar-control">
                <KeyboardArrowDownRounded style={editorToolOpen ? { transform: 'rotateX(180deg)' } : null} onClick={() => handleEditorToolBoolean()} />
              </div>
              <EditorToolbar editorToolOpen={editorToolOpen} />
            </div>
          </div>

          <div className='blog-edit-section' >

            <img className='blog-flower-1' src={Flower1} alt="flowers" />
            <img className='blog-flower-2' src={Flower2} alt="flowers" />

            <div className='editor-div'>

              <div className="image-and-title-container">
                <div className='image-container'>

                  <div className="img">
                    {
                      blog?.image !== null
                        ? <img className='post-image' src={blog?.image} alt={blog?.title} />
                        : <img className='post-image placeholder' src={placeholder} alt='test' />
                    }
                    <div className='upload-image-buttons' >
                      <button className='btn-primary' disabled={false} onClick={(e) => openUploadImageModal(blog?.id)}>
                        <PublishRounded className='buttons' />
                        {
                          blog?.image
                            ? "Update Image"
                            : "Add Image"
                        }
                      </button>

                      {
                        blog?.image !== null
                          ? <button className='btn-primary-border' onClick={null}><HighlightOffRounded className='buttons' /> Remove Image </button>
                          : null
                      }
                    </div>
                  </div>
                </div>

                <div className='title-and-author-container'>
                  <div className='title-and-author'>

                    {/* <div className='author-row'> */}
                    {/* <img src='https://d33byq9npfy6u9.cloudfront.net/2/2022/05/03184712/Glenn_Youngkin_Headshot-e1651603656923.jpg' className='author-image' alt='author image'></img> */}

                    {/* <span className='blog-author' onClick={(e) => getAllAuthors()}>{blog?.author[0]?.full_name ? blog?.author[0]?.full_name : 'Add a author'} </span> */}
                    {/* <span className='remove-blog-author' >{blog?.author[0]?.full_name ? <CloseRounded style={{ height: "15px", width: "15px" }} onClick={(e) => DeleteAuthor(drawerDetails.id, blog?.author[0].id)} /> : null} </span> */}
                    {/* {
                      drawerDetails?.author?.map((author, i) => {
                        return <div className='author-tag' onClick={() => handleModal('edit-blog-author', drawerDetails)}><AddRounded className='add-author' /> {author.full_name} </div>
                      })
                    } */}
                    {/* <div className='author-tag' onClick={() => handleModal('edit-blog-author', blog)}><AddRounded className='add-author' /> Author</div> */}
                    {/* </div> */}

                    <div className="blog-category">
                      <hr /><span>Farm Life</span>
                    </div>

                    <div className="title-container">
                      {/* <div contentEditable={true} className='title-editor' role="textbox" spellcheck="true" onInput={(e) => } placeholder="Title" >{createBlogObj.title}</div> */}
                      <div ref={titleRef} contentEditable={true} className='title-editor' onInput={handleTitleInput}></div>
                    </div>

                    <div className="blog-author-and-date">
                      <span>Emily Cooper</span>
                      <span></span>
                      <span>December 6, 2022</span>
                    </div>

                  </div>
                </div>
              </div>



              <div className='content-container'>
                <div className='text-editor-container'>


                  <ReactQuill className={editor} ref={quillRef}
                    theme="snow"
                    value={createBlogObj.post}
                    onChangeSelection={(e) => {
                      changeEditorStatus(e)
                    }}
                    onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, post: e }))}
                    placeholder={"Write something awesome..."}
                    modules={modules}
                    formats={formats}
                  />
                  {/* <ReactQuill className='myeditor' modules={{
                      toolbar: false  // hide the default toolbar
                    }} defaultValue='Blog Post' theme="snow" name="post" value={createBlogObj.post} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, post: e }))} /> */}

                </div>

              </div>
            </div>

          </div>

          <div className='tags-container'>
            <div className='topic-row'>
              {
                blog?.topics?.map((topic, i) => {
                  return <div key={i} className='single-tag' onClick={null}> {topic?.title} <CloseRounded className='remove-author' onClick={() => DeleteTopic(blog?.id, topic?.id)} /></div>
                })
              }
              <div className='single-tag' onClick={() => handleModal('edit-blog-topics', blog)}><AddRounded className='add-author' /> Topics</div>
            </div>

            <div className='tags-row'>
              {
                blog?.tags?.map((tag, i) => {
                  return <div key={i} className='single-tag' onClick={null}><CloseRounded className='remove-author' onClick={() => DeleteTag(blog?.id, tag?.id)} /> {tag?.title} </div>
                })
              }
              {/* <div className='single-tag' onClick={null}><CloseRounded className='add-author' onClick={() => null} /> Prayer</div> */}
              <div className='single-tag' onClick={() => handleModal('edit-blog-tags', blog)}><AddRounded className='add-author' /> Tag</div>
            </div>

            <div className='status-container'>

              Status -
              <div className="author-tags" onClick={() => openPublishModal(blog)}>
                <span className={statusClass}>{blog?.status.toUpperCase()}</span>
              </div>
            </div>
          </div>




        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <SnackbarContent style={{
          backgroundColor: 'teal',
          borderRadius: "10px",
          color: "white",
        }}
          message={<span id="client-snackbar">Post Saved</span>}
        />
      </Snackbar>
    </div>
  );
}

export default EditBlogDrawer;

