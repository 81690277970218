import { Card, Checkbox, FormControl, FormHelperText, Input, MenuItem, Radio, RadioGroup, Select, Slider, styled, TextField } from '@mui/material';
import React from 'react'
import CustomButton from '../../../common/CustomButton';
import activeImgae from '../../../assets/img/coupon/active.png';
import inActiveImgae from '../../../assets/img/coupon/inactive.png';

const PrettoSlider = styled(Slider)({
  color: '#2b1de8',
  height: 2,

  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#2b1de8',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

export default function CreateCouponSection({couponDealOption, selectCouponDealOption, setValidFor, errors, setError}) {

  const handleChange = (event) => {

    selectCouponDealOption({
      ...couponDealOption,
      selectedDealOptions: event.target.value
    })

  };

  const handleSelectChange = (event) => {
    selectCouponDealOption({
      ...couponDealOption,
      third: {
        ...couponDealOption.third,
        dealVal2: event.target.value
      }
    })
  };

  return (
    <div className='create-coupon-section-v2'>
      <div className='options-container'>
        <Card sx={{ backgroundColor: 'white', width: '100%', padding: '1rem 2rem' }}>
          <div className='heading-text-container'>
            <span className='title'>Coupon Deal</span>
            <span className='subtitle'>Select and custom your deal</span>
          </div>

          <div className='option-sub-container '>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={couponDealOption.selectedDealOptions}
              onChange={handleChange}
            >

              <div className={couponDealOption.selectedDealOptions == 0 ? 'coupon-deal-option-container ' : 'coupon-deal-option-container inactive-option'}>

                <Radio
                  overlay
                  value={0}
                  sx={{}}
                  slotProps={{
                    action: ({ checked }) => ({
                      sx: (theme) => ({
                        ...(checked && {
                          inset: -1,
                          border: '2px solid',
                          borderColor: theme.vars.palette.primary[500],
                        }),
                      }),
                    }),
                  }}
                />
                <div className='img-block'>

                  <img src={couponDealOption.selectedDealOptions == 0 ? activeImgae : inActiveImgae} />

                  <div className='img-block-content'>
                    <div className='img-deal'>{couponDealOption.first.dealVal1}</div>
                    <div className='img-deal'>{couponDealOption.first.dealVal2}</div>
                    <div className='img-dealunit'>FREE</div>
                  </div>

                </div>

                <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '5ch', marginRight: '50px' }}>
                  <Input
                    id="standard-adornment-weight"
                    onChange={(e) => selectCouponDealOption({
                      ...couponDealOption,
                      first: {
                        ...couponDealOption.first,
                        dealVal1: e.target.value
                      }
                    })}
                    value={couponDealOption.first.dealVal1}
                    placeholder='1'
                    aria-describedby="standard-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                    onFocus={() => setError('couponDeal', false)}
                  />
                  <FormHelperText id="standard-weight-helper-text">Max 5 characters</FormHelperText>
                </FormControl>

                <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '10ch', marginRight: "50px" }}>
                  <Input
                    id="standard-adornment-weight"
                    onChange={(e) => selectCouponDealOption({
                      ...couponDealOption,
                      first: {
                        ...couponDealOption.first,
                        dealVal2: e.target.value
                      }
                    })}
                    value={couponDealOption.first.dealVal2}
                    placeholder='Chicken'
                    aria-describedby="standard-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                    onFocus={() => setError('couponDeal', false)}
                  />
                  <FormHelperText id="standard-weight-helper-text">Max 10 characters</FormHelperText>
                </FormControl>

                <span>FREE</span>
              </div>

              <div className={couponDealOption.selectedDealOptions == 1 ? 'coupon-deal-option-container ' : 'coupon-deal-option-container inactive-option'}>

                <Radio
                  overlay
                  value={1}
                  sx={{}}
                  slotProps={{
                    action: ({ checked }) => ({
                      sx: (theme) => ({
                        ...(checked && {
                          inset: -1,
                          border: '2px solid',
                          borderColor: theme.vars.palette.primary[500],
                        }),
                      }),
                    }),
                  }}
                />
                <div className='img-block'>

                  <img src={couponDealOption.selectedDealOptions == 1 ? activeImgae : inActiveImgae} />

                  <div className='img-block-content'>
                    <div className='img-deal'>{couponDealOption.second.dealVal1}%</div>
                    <div className='img-dealunit'>OFF</div>
                  </div>

                </div>

                <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '5ch', marginRight: '50px' }}>
                  <Input
                    onChange={(e) => selectCouponDealOption({
                      ...couponDealOption,
                      second: {
                        ...couponDealOption.second,
                        dealVal1: e.target.value
                      }
                    })}

                    value={couponDealOption.second.dealVal1}
                    id="standard-adornment-weight"
                    placeholder='30'
                    aria-describedby="standard-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                    onFocus={() => setError('couponDeal', false)}
                  />
                  <FormHelperText id="standard-weight-helper-text">Max 5 characters</FormHelperText>
                </FormControl>

                <span>% OFF</span>
              </div>
              <div className={couponDealOption.selectedDealOptions == 2 ? 'coupon-deal-option-container ' : 'coupon-deal-option-container inactive-option'}>

                <Radio
                  overlay
                  value={2}
                  sx={{}}
                  slotProps={{
                    action: ({ checked }) => ({
                      sx: (theme) => ({
                        ...(checked && {
                          inset: -1,
                          border: '2px solid',
                          borderColor: theme.vars.palette.primary[500],
                        }),
                      }),
                    }),
                  }}
                />
                <div className='img-block'>

                  <img src={couponDealOption.selectedDealOptions == 2 ? activeImgae : inActiveImgae} />


                  <div className='img-block-content'>
                    <div className='img-deal'>{couponDealOption.third.dealVal1}</div>
                    <div className='img-dealunit'>{couponDealOption.third.dealVal2.toUpperCase()}</div>
                  </div>

                </div>

                <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '5ch', marginRight: '80px' }}>
                  <Input
                    onChange={(e) => selectCouponDealOption({
                      ...couponDealOption,
                      third: {
                        ...couponDealOption.third,
                        dealVal1: e.target.value
                      }
                    })}
                    value={couponDealOption.third.dealVal1}
                    id="standard-adornment-weight"
                    aria-describedby="standard-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                    onFocus={() => setError('couponDeal', false)}
                  />
                  <FormHelperText id="standard-weight-helper-text">Max 5 characters</FormHelperText>

                </FormControl>

                <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '8ch', marginRight: '50px' }}>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={couponDealOption.third.dealVal2}
                    onChange={handleSelectChange}
                    defaultValue={'zar'}
                  >

                    <MenuItem value={'zar'}>ZAR</MenuItem>
                    <MenuItem value={'usd'}>USD</MenuItem>
                    <MenuItem value={'aud'}>AUD</MenuItem>
                    <MenuItem value={'inr'}>INR</MenuItem>
                  </Select>
                </FormControl>

              </div>

            </RadioGroup>
            <div className='coupon-deal-option-container'>
            </div>
          </div>

          {errors?.couponDeal?.show && <div className="coupon-error-text">{errors.couponDeal.message}</div>}
        </Card>
        <div className='option-sub-container'>
          <Card sx={{ backgroundColor: 'white', height: '250px', padding: '1rem 2rem', display: 'flex', flexDirection: "column", gap: '1rem', alignItems: 'center', width: "100%" }}>
            <span className='title'>
              Coupon Expiration
            </span>

            <div className='multilple-button-container'>
              <CustomButton
                disabled={couponDealOption.couponExpiration}
                style={couponDealOption.expirationButton == 1 ? { opacity: "1" } : { opacity: '.2' }}
                onClick={() => {
                  selectCouponDealOption({
                    ...couponDealOption,
                    expirationButton: 1
                  })
                }} >Short term 1-3 weeks</CustomButton>
              <CustomButton
                disabled={couponDealOption.couponExpiration}
                style={couponDealOption.expirationButton == 2 ? { opacity: "1" } : { opacity: '.2' }}
                onClick={() => {
                  selectCouponDealOption({
                    ...couponDealOption,
                    expirationButton: 2
                  })
                }}>Mid term 1- 11 months</CustomButton>
              <CustomButton
                disabled={couponDealOption.couponExpiration}
                style={couponDealOption.expirationButton == 3 ? { opacity: "1" } : { opacity: '.2' }}
                onClick={() => {
                  selectCouponDealOption({
                    ...couponDealOption,
                    expirationButton: 3
                  })
                }}>Special occasions 1-6 days</CustomButton>
              <CustomButton
                disabled={couponDealOption.couponExpiration}
                style={couponDealOption.expirationButton == 4 ? { opacity: "1" } : { opacity: '.2' }}
                onClick={() => {
                  selectCouponDealOption({
                    ...couponDealOption,
                    expirationButton: 4
                  })
                }} >Long term 1-2 years</CustomButton>
            </div>

            <PrettoSlider aria-label="Temperature"
              // defaultValue={selectedButton == 1 ? 20 : selectedButton == 2 ? 40 : selectedButton == 3 ? 60 : 80}
              value={couponDealOption.expirationOption}
              onChange={(e, value) => selectCouponDealOption({
                ...couponDealOption,
                expirationOption: value
              })}
              disabled={couponDealOption.couponExpiration}
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={couponDealOption.expirationButton == 1 ? 3 : couponDealOption.expirationButton == 2 ? 11 : couponDealOption.expirationButton == 3 ? 6 : 2} />

            <div className='checkbox-text'>
              <Checkbox checked={couponDealOption.couponExpiration} onChange={(e) => {

                selectCouponDealOption({
                  ...couponDealOption,
                  couponExpiration: e.target.checked
                })

              }}
                sx={{
                  color: "#2b1de8",
                  '&.Mui-checked': {
                    color: "#2b1de8",
                  },
                }}
                inputProps={{
                  'aria-label': 'Checkbox A',
                }}
              />
              <span>Coupon does not expire</span>
            </div>
          </Card>
          <Card sx={{ backgroundColor: 'white', height: '250px', width: "100%", padding: '1rem 2rem', display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'end' }}>
            <span className='title'>Coupon Description</span>

            <div className='text-container'>
              <TextField
                id="standard-multiline-static"
                value={couponDealOption.description}
                onChange={(e) => selectCouponDealOption({
                  ...couponDealOption,
                  description: e.target.value
                })}
                onFocus={() => setError('couponDescription', false)}
                fullWidth
                multiline
                rows={3}
                placeholder='Brief specific description of the deal to inform the audience what you are offering with the coupon.'
                variant="standard"
              />
              <img src={"https://admin.delivait.com/assets/img/description.png"}></img>
            </div>
            {errors?.couponDescription?.show && <div className="coupon-error-text">{errors.couponDescription.message}</div>}

          </Card>
        </div>
      </div>

    </div>
  )
}
