//react
import { useContext, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

//packasges
import { ToastContainer } from 'react-toastify';


//Layout
import Footer from './Layout/Footer/Footer';
import Main from './Layout/Main/Main';
import Sidebar from './Layout/Main/Sidebar';
import Navbar from './Layout/Navbar/Navbar';

//pages
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import Resources from "./Pages/Resources/Resources";
import Overview from "./Pages/Overview/Overview";
import Agents from "./Pages/Agents/Agents";
import Users from "./Pages/Users/Users";
import Companies from "./Pages/Companies/Companies";
import Blogs from './Pages/Blogs/Blogs';
import Tags from './Pages/Tags/Tags';
import Topics from './Pages/Topics/Topics';

//context
import AuthContext from './context/AuthContext';
import { DrawerContextProvider } from "./context/DrawerContext";
import { ModalContextProvider } from './context/ModalContext';

//drawer & modal
import DetailsDrawer from "./common/DetailsDrawer";
import DetailsModal from "./common/DetailsModal";
import ERServicesPage from './Pages/ERServices/ERServices';
import Coupons from './Pages/Coupons/Coupons';
import Categories from './Pages/Categories/Categories';
import CreateCoupons from './Pages/Create-Coupons/Create-Coupons';
import { CouponProvider } from './context/CouponContext';
import CreateCouponV2 from './Pages/CreateCouponV2/CreateCouponV2';
import { CouponProviderV2 } from './context/CouponContextV2';


function App() {

  const authCtx = useContext(AuthContext)

  const [sidebarOpen, setSidebarOpen] = useState(true)

  return (
    <main className="app-container">
      <DrawerContextProvider>
        <ModalContextProvider>
          <CouponProviderV2>
            <CouponProvider>
              <BrowserRouter>
                {/* <Navbar /> */}
                <div className="main-container">
                  <Sidebar setSidebarOpen={setSidebarOpen} />
                  <div className='main' style={sidebarOpen ? { width: 'calc(100% - 200px)' } : { width: 'calc(100% - 65px)' }} >
                    <Routes>
                      <Route path="/" exact index element={!authCtx.isLoggedIn ? <Navigate to='/login' /> : <Navigate to='/overview' />} />
                      <Route path="/login" exact index element={!authCtx.isLoggedIn ? <Login /> : <Navigate to='/overview' />} />
                      <Route path="/register" exact element={authCtx.isLoggedIn ? <Register /> : <Navigate to='/login' />} />
                      <Route path="/overview" exact element={authCtx.isLoggedIn ? <Overview sidebarOpen={sidebarOpen} /> : <Navigate to='/login' />} />
                      <Route path="/resource" exact element={authCtx.isLoggedIn ? <Resources /> : <Navigate to='/login' />} />
                      <Route path="/agents" exact element={authCtx.isLoggedIn ? <Agents /> : <Navigate to='/login' />} />
                      <Route path="/users" exact element={authCtx.isLoggedIn ? <Users /> : <Navigate to='/login' />} />
                      <Route path="/companies" exact element={authCtx.isLoggedIn ? <Companies /> : <Navigate to='/login' />} />
                      <Route path="/coupons" exact element={authCtx.isLoggedIn ? <Coupons /> : <Navigate to='/login' />} />
                      <Route path="/create-coupons" exact element={authCtx.isLoggedIn ? <CreateCouponV2 /> : <Navigate to='/login' />} />
                      <Route path="/create-coupons-v2" exact element={authCtx.isLoggedIn ? <CreateCouponV2 /> : <Navigate to='/login' />} />
                      <Route path="/emergency-services" exact element={authCtx.isLoggedIn ? <ERServicesPage /> : <Navigate to='/login' />} />
                      <Route path="/categories" exact element={authCtx.isLoggedIn ? <Categories /> : <Navigate to='/login' />} />
                      <Route path="/blogs" exact element={authCtx.isLoggedIn ? <Blogs /> : <Navigate to='/login' />} />
                      <Route path="/tags" exact element={authCtx.isLoggedIn ? <Tags /> : <Navigate to='/login' />} />
                      <Route path="/topics" exact element={authCtx.isLoggedIn ? <Topics /> : <Navigate to='/login' />} />
                    </Routes>
                  </div>
                </div>
                {/* <Footer /> */}
                <DetailsDrawer />
                <DetailsModal />
              </BrowserRouter>
              <ToastContainer />
            </CouponProvider>
          </CouponProviderV2>
        </ModalContextProvider>
      </DrawerContextProvider>
    </main>
  );
}

export default App;
