import React, { useContext, useEffect, useState } from 'react'

import AuthContext from '../../context/AuthContext';

import { toast } from 'react-toastify';

import ChooseCompany from '../../components/CreateCouponV2/ChooseCompany/ChooseCompany';
import HeaderStepper from '../../components/CreateCouponV2/HeaderStepper/HeaderStepper';
import CreateCouponSection from '../../components/CreateCouponV2/CreateCoupon/CreateCouponSection';
import TermsInstructions from '../../components/CreateCouponV2/TermsInstructions/TermsInstructions';
import CustomButton from '../../common/CustomButton';
import { CircularProgress } from '@mui/material';
import CouponDetails from '../../components/CreateCouponV2/CouponDetails/CouponDetails';
import Location from '../../components/CreateCouponV2/Location/Location';
import ConfirmCoupon from '../../components/CreateCouponV2/ConfirmCoupon/ConfirmCoupon';
import { useNavigate } from 'react-router-dom';
import { useCouponContextV2 } from '../../context/CouponContextV2';

export default function CreateCouponV2() {

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const { couponState: coupon, updateCouponState: updateCoupon, clearState: clearCoupon } = useCouponContextV2();
  const [errors, setErrors] = useState({
    couponDeal: {
      message: "Please enter the coupon deal*",
      show: false
    },
    couponDescription: {
      message: "Please add the coupon description*",
      show: false
    }, 
    terms: {
      message: "Please select the terms and conditions*",
      show: false
    },
    instructions: {
      message: "Please select the instructions*",
      show: false
    },
    categories: {
      message: "Please select upto 3 categories*",
      show: false
    },
    website: {
      message: "Please enter coupon website URL*",
      show: false
    },
    countries: {
      message: "Please enter eligible countries*",
      show: false
    },
    phone: {
      message: "Please enter mobile number*",
      show: false
    },
    mail: {
      message: "Please enter email address*",
      show: false
    },
    keywords: {
      message: "Please add coupon keywords*",
      show: false
    },
    code: {
      message: "Please enter coupon code*",
      show: false
    },
    location: {
      message: "Please select a location*",
      show: false
    },
    cta: {
      message: "Please select the action buttons*",
      show: false
    },
  })

  const steps = [
    'Deal',
    'Terms & Conditions',
    'Coupon Details',
    'Location',
    'Confirm'
  ];

  // Common
  const currentStep = coupon?.currentStep;
  const setCurrentStep = (step) => {
    updateCoupon({currentStep: step})
  }
  const [submittingCoupon, setSubmittingCoupon] = useState(false);

  // Step 0
  const selectedCompany = coupon?.selectedCompany;
  const setSelectedCompany = (_company) => {
    updateCoupon({selectedCompany: _company})
  }

  // Step 1
  const couponDealOption = coupon?.couponDealOption;
  const setCouponDealOption = (_couponDealOption) => {
    updateCoupon({couponDealOption: _couponDealOption})
  }
  
  const validFor = coupon?.validFor;
  const setValidFor = (_validFor) => {
    updateCoupon({validFor: _validFor})
  }

  // Step2
  const termsConditions = coupon?.termsConditions;
  const setTermsConditions = (_terms) => {
    updateCoupon({termsConditions: _terms})
  }
  
  const instructions = coupon?.instructions;
  const setInstructions = (_instructions) => {
    updateCoupon({instructions: _instructions})
  }

  // Step 3
  const couponDetails = coupon?.couponDetails;
  const setCouponDetails = (_couponDetails) => {
    updateCoupon({couponDetails: _couponDetails})
  }
  const [orderedCategoriesData, setOrderedCategoriesData] = useState([]);

  // Step 4
  const locationDetails = coupon?.locationDetails;
  const setLocationDetails = (_locationDetails) => {
    updateCoupon({locationDetails: _locationDetails})
  }


  // Step 0
  // --------------------------------

  // Step 1
  const getExpirationOption = (selectedButton) => {

    switch (selectedButton) {
      case 1:
        return "Weeks";
      case 2:
        return "Months";
      case 3:
        return "Days";
      case 4:
        return "Years";
    }

  }

  const selectCouponDealOption = (_couponDealOption) => {
    setCouponDealOption(_couponDealOption);
    setValidFor(`${_couponDealOption.expirationOption} ${getExpirationOption(_couponDealOption.expirationButton)}`)
  }

  // Step 2
  const addTermsConditions = (_termCondition) => {
    setTermsConditions([...termsConditions, _termCondition]);
  }
  const addInstructions = (_instruction) => {
    setInstructions([...instructions, _instruction]);
  }
  const toggleTermCondition = (_id) => {
    let temp = [...termsConditions];
    const target = temp.find(obj => obj.id === _id);
    if (target) {
      target.checked = !target.checked;
    }
    setTermsConditions([...temp]);
  };
  const toggleInstruction = (_id) => {
    let temp = [...instructions];
    const target = temp.find(obj => obj.id === _id);
    if (target) {
      target.checked = !target.checked;
    }
    setInstructions([...temp]);
  };
  const checkAnySelected = () => {
    const selectedInstruction = instructions.filter((item) => item.checked == true);
    const selectedTerms = termsConditions.filter((item) => item.checked == true);

    return (selectedInstruction.length && selectedTerms.length);
  }
  const checkTermsSelected = () => {
    const selectedTerms = termsConditions.filter((item) => item.checked == true);

    return (selectedTerms.length);
  }
  const checkInstructionsSelected = () => {
    const selectedInstruction = instructions.filter((item) => item.checked == true);

    return (selectedInstruction.length);
  }

  const createCoupon = () => {

    setSubmittingCoupon(true)

    const getSelectedDeal = () => {

      if (couponDealOption.selectedDealOptions == 0) {

        return couponDealOption.first.dealVal1

      } else if (couponDealOption.selectedDealOptions == 1) {

        return couponDealOption.second.dealVal1

      } else {

        return couponDealOption.third.dealVal1

      }
    }
    const getSelectedDealUnit = () => {

      if (couponDealOption.selectedDealOptions == 0) {

        return 'INR'

      } else if (couponDealOption.selectedDealOptions == 1) {

        return 'INR'

      } else {

        return 'INR'

      }
    }


    const payload = {
      newCoupon: coupon.newCoupon,
      coupon: couponDetails.couponCode,
      companyId: selectedCompany?.id,
      contactId: "1",
      description: couponDealOption.description,
      // validFor: couponDealOption?.couponExpiration ? 'Coupon does not expire' : validFor,
      validFor: validFor,
      locations: locationDetails?.locations?.filter(lc => locationDetails?.checkedId?.includes(lc.id)),
      categories: couponDetails.categories,
      website: couponDetails.couponClaimUrl,
      mobile: couponDetails.phoneNumber,
      email: couponDetails.emailAddress,
      onlineOnly: locationDetails.onlineOnly,
      keywords: couponDetails.keywords,
      // countries: couponDetails.countries,
      countries: [1],
      deal: getSelectedDeal(),
      dealUnit: getSelectedDealUnit(),
      termsAndConditions: (termsConditions?.filter(tnc => tnc.checked == true))?.map(tnc => tnc?.instruction),
      instructions: (instructions?.filter(ins => ins.checked == true))?.map(ins => ins?.instruction),
      userScope: "agen"
    }

    try {
      fetch(`${process.env.REACT_APP_API_URI}/v3/admin/coupon`, {
        method: "POST",
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify(payload)
      }).then((res) => { return res.json() }).then((data) => {
        if (data.status == 'success') {
          setSubmittingCoupon(false)
          navigate('/coupons');
          localStorage.removeItem("deliva-admin-active-coupon-details");
          if(couponDetails.newCoupon){
            toast("Coupon created successfully.")
          }
          else{
            toast("Coupon updated successfully.")
          }
        } else {
          console.error(data);
          setSubmittingCoupon(false)
        }
      })
    } catch (e) {
      console.log(e);
    }

  }


  // Common

  const moveToNextStep = (_step) => {
    setCurrentStep(_step);
  }

  const resetCoupon = () => {
    // Clear Context here
    clearCoupon();
  }

  const setError = (_keyname, show) => {
    let errs = {...errors};
    errs[_keyname].show = show;
    setErrors(errs);
  }

  console.log(errors);

  const handleNextStep = (_currentStep) => {

    switch (_currentStep) {

      case 1:
        if (couponDealOption.selectedDealOptions == 0 && couponDealOption.first.dealVal1 != "" && couponDealOption.first.dealVal2 != "" && couponDealOption.description != "") {
          setCurrentStep(_currentStep + 1);
          setError('couponDeal', false);
          setError('couponDescription', false);
        } else if (couponDealOption.selectedDealOptions == 1 && couponDealOption.second.dealVal1 != "" && couponDealOption.description != "") {
          setCurrentStep(_currentStep + 1);
          setError('couponDeal', false);
          setError('couponDescription', false);
        } else if (couponDealOption.selectedDealOptions == 2 && couponDealOption.third.dealVal1 != "" && couponDealOption.third.dealVal2 != "" && couponDealOption.description != "") {
          setCurrentStep(_currentStep + 1);
          setError('couponDeal', false);
          setError('couponDescription', false);
        } else {
          if(!couponDealOption.description || couponDealOption.description === ""){
            setError('couponDescription', true);
          }
          if((couponDealOption.first.dealVal1 === "" && couponDealOption.first.dealVal2 === "") || couponDealOption.second.dealVal1 === "" || couponDealOption.third.dealVal1 === "") {
            setError('couponDeal', true);
          }
          toast("Please fill all the details!");
        }
        break;

      case 2:
        if (checkTermsSelected() && checkInstructionsSelected()) {
          setCurrentStep(_currentStep + 1);
          setError('terms', false);
          setError('instructions', false);
        } else {
          if(!checkTermsSelected()){
            setError('terms', true);
          }
          if(!checkInstructionsSelected()){
            setError('instructions', true);
          }
          toast("Please fill all the details!");
        }

        break;

      case 3:
        if (couponDetails.categories.length != 0 && couponDetails.categories.length <= 3 && couponDetails.couponClaimUrl != "" && couponDetails.phoneNumber != "" && couponDetails.emailAddress != null && couponDetails.countries.length >= 1 && couponDetails.keywords.length >= 1 && couponDetails.couponCode != "") {
          setCurrentStep(_currentStep + 1);
          setError('categories', false);
          setError('website', false);
          setError('countries', false);
          setError('phone', false);
          setError('mail', false);
          setError('keywords', false);
          setError('code', false);
        } else if (couponDetails.categories.length > 3) {
          setError('categories', true);
          toast("Please select only 3 categories");
        } else {
          toast("Please fill all the details!");
          console.log(couponDetails);
          if(couponDetails.categories.length === 0 || couponDetails.categories.length > 3) setError('categories', true);
          if(couponDetails.countries.length === 0) setError('countries', true);
          if(couponDetails.couponClaimUrl === "") setError('website', true);
          if(couponDetails.phoneNumber === "") setError('phone', true);
          if(couponDetails.emailAddress === "") setError('mail', true);
          if(couponDetails.keywords.length === 0) setError('keywords', true);
          if(couponDetails.couponCode === "") setError('code', true);
        }
        break;
      case 4:
        if (locationDetails?.locations?.length > 0) {
          if (locationDetails?.checkedId?.length > 0) {
            let checked = locationDetails?.callToActionButton?.filter(cta => cta.checked === true);
            if (locationDetails?.onlineOnly === "yes" || checked.length > 0) {
              setError('location', false);
              setError('cta', false);
              setCurrentStep(currentStep + 1);
            }
            else {
              setError('cta', true);
              toast("Please select Call to Action Buttons!")
            }
          }
          else {
            setError('location', true);
            toast("Please select a location!")
          }
        }
        else {
          setError('location', true);
          toast("Please add new location!")
        }
        break;

      case 5:
        createCoupon()
        break;
    }

  }

  const checkErrors = () => {
    let errorsExist = false;
    Object.keys(errors).forEach(key => {
      if(errors[key].show) errorsExist =  true;
    })
    return errorsExist;
  }

  return (
    <div className='create-coupon-container-v2'>
      {currentStep === 0 && <ChooseCompany selectedCompany={selectedCompany} selectCompany={setSelectedCompany} moveToNextStep={moveToNextStep} />}

      {currentStep >= 1 && <HeaderStepper steps={steps} currentStep={currentStep} selectedCompany={selectedCompany} resetCoupon={resetCoupon} />}

      {currentStep === 1 && <CreateCouponSection couponDealOption={couponDealOption} selectCouponDealOption={selectCouponDealOption} setValidFor={setValidFor} errors={errors} setError={setError} />}

      {currentStep === 2 && <TermsInstructions termsConditions={termsConditions} instructions={instructions} addTermsConditions={addTermsConditions} addInstructions={addInstructions} toggleTermCondition={toggleTermCondition} toggleInstruction={toggleInstruction} errors={errors} setError={setError} />}

      {currentStep === 3 && <CouponDetails couponDetails={couponDetails} setCouponDetails={setCouponDetails} orderedCategoriesData={orderedCategoriesData} setOrderedCategoriesData={setOrderedCategoriesData} errors={errors} setError={setError} />}

      {currentStep === 4 && <Location moveToNextStep={moveToNextStep} locationDetails={locationDetails} setLocationDetails={setLocationDetails} company={selectedCompany} errors={errors} setError={setError} />}

      {currentStep === 5 && <ConfirmCoupon selectedCompany={selectedCompany} couponDealOption={couponDealOption} couponDetails={couponDetails} orderedCategoriesData={orderedCategoriesData} termsConditions={termsConditions} instructions={instructions} locationDetails={locationDetails} />}


      {
        currentStep >= 1 &&
        <div className='footer-buttons '>
          <CustomButton className={'deliva-btn-bordered'} onClick={() => setCurrentStep(currentStep - 1)}>Back</CustomButton>
          <CustomButton onClick={() => submittingCoupon ? null : handleNextStep(currentStep)} >
            {
              submittingCoupon
                ? <CircularProgress size={20} />
                : "Confirm"
            }
          </CustomButton>
        </div>
      }
    </div>
  )
}
