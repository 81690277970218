import { useContext, useState, useCallback, createRef } from 'react';

//context
import AuthContext from '../../../context/AuthContext';
import ModalContext from '../../../context/ModalContext';
import DrawerContext from '../../../context/DrawerContext';

//firebase
import { uploadBytes, ref as sRef, database } from "../../Firebase/FirebaseConfig";
import storage from "../../Firebase/FirebaseConfig";

//react crop
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Base64 } from 'js-base64';

//mui
import { CircularProgress } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';

//dropzone
import Dropzone from './Dropzone';

import { getDownloadURL } from 'firebase/storage';

const UploadFixedImageModal = () => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const cropperRef = createRef();

  const [backupImage, setBackupImage] = useState(null);
  const [image, setImage] = useState();
  const [finalImage, setFinalImage] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);


  // get dropped images
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
        setBackupImage(e.target.result);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);



  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      setFinalImage(baseToFile(cropperRef.current?.cropper.getCroppedCanvas().toDataURL()))
    }
  };

  const resetCropImage = () => {
    setImage(backupImage)
    setCropData(null)
    setFinalImage(undefined)
  }

  // this returns a file object from a base file
  const baseToFile = (base) => {
    if (base) {
      let arr = base?.split(",")
      let mime = arr[0].match(/:(.*?);/)[1]
      let data = arr[1]

      let dataStr = Base64.atob(data)
      let n = dataStr.length
      let dataArr = new Uint8Array(dataStr.length)

      while (n--) {
        dataArr[n] = dataStr.charCodeAt(n)
      }

      let file = new File([dataArr], `img_cropper_${Date.now()}.png`, { type: mime })
      // console.log(file)
      return file
    } else {
      return null
    }
  }

  // decides where to send the image based on the origin of the context
  const handleUploadLocation = () => {
    switch (modalCtx.details.origin) {
      case 'blog':
        uploadBlogImg();
        break;

      case 'product-display-image':
        uploadProductDisplayImg();
        break;

      case 'product-additional-image':
        uploadProductAdditionalImg();
        break;

      case 'update-product-additional-image':
        updateProductAdditionalImg();
        break;

      default:
        break;
    }
  }


  // upload image to firebase and then to PUT in blog object
  const uploadBlogImg = () => {

    let img = baseToFile(cropData ? cropData : image);

    if (img) {

      setIsUploading(true)
      let coverRef = `/blogs/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {

        getDownloadURL(storageRef)
          .then(async (imageUrl) => {

            const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/blog/${modalCtx.details.id}`, {
              method: 'PUT',
              mode: 'cors',
              headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
              body: JSON.stringify({ image: imageUrl })
            })
            const data = await response.json()

            if (data.status === "success") {
              setIsUploading(false)
              modalCtx.closeModal();
            }

          })

      }, (error) => console.log(error)

      )
    } else {
      console.log("no img");
    }
  }

  // upload image to firebase and then to PUT in product object
  const uploadProductDisplayImg = () => {

    let img = baseToFile(cropData ? cropData : image);

    if (img) {

      setIsUploading(true)
      let coverRef = `/products/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {

        getDownloadURL(storageRef)
          .then(async (imageUrl) => {

            const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/product/${modalCtx.details.id}`, {
              method: 'PUT',
              mode: 'cors',
              headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
              body: JSON.stringify({ image: imageUrl })
            })
            const data = await response.json()

            if (data.status === "success") {
              setIsUploading(false)
              modalCtx.closeModal();
            }

          })

      }, (error) => console.log(error)

      )
    } else {
      console.log("no img");
    }
  }

  // upload image to firebase and then to PUT in product object
  const uploadProductAdditionalImg = () => {

    let img = baseToFile(cropData ? cropData : image);

    if (img) {

      setIsUploading(true)
      let coverRef = `/products/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {

        getDownloadURL(storageRef)
          .then(async (imageUrl) => {

            const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/product/${modalCtx.details.id}/image`, {
              method: 'POST',
              mode: 'cors',
              headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
              body: JSON.stringify({ images: [imageUrl] })
            })
            const data = await response.json()

            if (data.status === "success") {
              setIsUploading(false)
              modalCtx.closeModal();
            }

          })

      }, (error) => console.log(error)

      )
    } else {
      console.log("no img");
    }
  }

  // update image to firebase and then to PUT in product object
  const updateProductAdditionalImg = () => {

    let img = baseToFile(cropData ? cropData : image);

    if (img) {

      setIsUploading(true)
      let coverRef = `/products/${img.name}`;
      console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      uploadBytes(storageRef, img).then((snapshot) => {

        getDownloadURL(storageRef)
          .then(async (imageUrl) => {

            const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/product/${modalCtx.details.id}/image`, {
              method: 'PUT',
              mode: 'cors',
              headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
              body: JSON.stringify({ image: imageUrl })
            })
            const data = await response.json()

            if (data.status === "success") {
              setIsUploading(false)
              modalCtx.closeModal();
            }

          })

      }, (error) => console.log(error)

      )
    } else {
      console.log("no img");
    }
  }

  // close 
  const onClose = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  };

  return (
    <div className='upload-image-modal-container'>
      <div className="title">
        <h2>Upload Image</h2>
        <CloseRounded onClick={() => onClose()} />
      </div>
      <div className='crop'>
        {
          image
            ?
            finalImage == undefined
              ? <Cropper
                ref={cropperRef}
                style={{ height: "100%", width: "100%" }}
                zoomTo={0.3}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                guides={true}
                // ? if no aspect ration is provided, the cropper will be in free crop mode
                aspectRatio={modalCtx.details.aspectRatio ? modalCtx.details.aspectRatio : undefined}
              />
              : <img src={cropData} style={{ height: "100%", width: "100%", objectFit: 'contain' }} alt="preview" />
            : <Dropzone onDrop={onDrop} accept={"image/*"} />
        }
      </div>
      <div className='crop-btns'>


        {
          // ? the crop button will only show once the image is present in the setImage state
          !finalImage && image
            ? <button className='btn-primary' onClick={() => getCropData()} disabled={!image}>Crop</button>
            : null
        }

        {
          // ? this reset button will reset the image and cropper 
          finalImage
            ? <button className='btn-primary' onClick={() => resetCropImage()} disabled={!image}>Reset</button>
            : null
        }
        {
          finalImage
            ? <button className='btn-primary' onClick={() => handleUploadLocation()} disabled={!image}>
              {
                isUploading
                  ? <CircularProgress size={18} style={{ color: '#FFF' }} />
                  : 'Upload'
              }
            </button>
            : null
        }

        <div className="tips">
          <span>! Use mouse scroll wheel to zoom in and out of the image</span>
        </div>
      </div>
    </div>
  );

}

export default UploadFixedImageModal;
