// react
import React, { useEffect, useState, createRef } from 'react';

//HighCharts
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { chart } from 'highcharts';
import mapWorld from '../world.json';

//assets
import CompanyIcon from '../../assets/img/overview/total_companies.png'
import CouponIcon from '../../assets/img/overview/total_coupons.png'
import UsersIcon from '../../assets/img/overview/total_users.png'

// Load Highcharts modules
require('highcharts/indicators/indicators')(Highcharts)
require('highcharts/indicators/pivot-points')(Highcharts)
require('highcharts/indicators/macd')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/map')(Highcharts)
// require('highcharts/modules/accessibility')(Highcharts)


const Overview = ({ sidebarOpen }) => {


  const divRef = createRef()

  const size = useWindowSize();

  // Hook
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  // charts data
  //Pie charts common options
  const pieChart = {
    chart: {
      type: 'pie',
      events: {
        load: function () {
          if (Highcharts) {
            // setTimeout(() => { Highcharts?.reflow(); }, 0);
          }
        }
      }
    },
    credits: { enabled: false },
    plotOptions: {
      pie: {
        size: '100%',
        allowPointSelect: false,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
          // enabled: true,
          // format: '{point.name}: {point.percentage:.1f}%'
        },
        showInLegend: true
      },
    }
  };

  //line graph common options
  const LineGraph = {
    chart: {
      type: 'spline',
      events: {
        load: function () {
          if (Highcharts) {
            // setTimeout(() => { Highcharts?.reflow(); }, 0);
          }
        }
      }
    },
    title: {
      text: null
    },

    subtitle: {
      text: null
    },
    credits: { enabled: false },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%b %d ',
        month: '%b %e',
        year: '%b'
      },
    },

    yAxis: [{ // left y axis
      title: {
        text: null
      },
      labels: {
        align: 'left',
        x: 3,
        y: 16,
        format: '{value:.,0f}'
      },
      showFirstLabel: false
    }, { // right y axis
      linkedTo: 0,
      gridLineWidth: 0,
      opposite: true,
      title: {
        text: null
      },
      labels: {
        align: 'right',
        x: -3,
        y: 16,
        format: '{value:.,0f}'
      },
      showFirstLabel: false
    }],

    legend: {
      align: 'left',
      verticalAlign: 'top',
      borderWidth: 0
    },

    tooltip: {
      shared: true,
      crosshairs: true
    },
  };

  //high map common options
  const highMap = {
    chart: {
      map: mapWorld,
      events: {
        load: function () {
          if (Highcharts) {
            // setTimeout(() => { Highcharts?.reflow(); }, 0);
          }
        }
      }
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom'
      }
    },
    credits: { enabled: false },
    legend: {
      enabled: true
    },
    colorAxis: {
      min: 0
    },

  };

  //registration timeline line graph
  const registrationsLineGraphData = {
    colors: ['#171f5f', '#e57c79'],
    series: [
      {
        data: [7539567, 6598591, 7022524.5, 7572023.5, 7573023.5],
        color: '#171f5f',
        name: 'alon',
        visible: true
      },
      {
        data: [6539557, 7598491, 7032524.5, 6572023.5, 7573023.5],
        dashStyle: 'DashDot',
        name: 'avner',
        hide: true,
      }
    ]
    // [
    //     {
    //     name: "Number of Companies Registrations",
    //     data: []
    //     },
    //     {
    //     name: "Number of Coupons Registrations",
    //     data: []
    //     }
    // ]
  };

  //Coupons Pie Chart
  const couponsPieChartData = {
    title: {
      text: 'Coupons'
    },
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f} %</b><br>Number of companies: <b>{point.y}</b>'
    },
    colors: ['#c0de91', '#e57c79', '#f6ca91', '#90d8cd', '#796d76', '#2d90cb'],
    series: [{
      name: null,
      colorByPoint: true,
      data: [
        {
          name: '1 Coupon',
          y: 75
        },
        {
          name: '2 Coupons',
          y: 300
        },
        {
          name: '3 Coupons',
          y: 100
        },
        {
          name: '4 Coupons',
          y: 80
        },
        {
          name: '5 Coupons',
          y: 150
        },
        {
          name: '6+ Coupons',
          y: 130
        }
      ]
    }],
  };

  //Locations Pie Chart
  const locationsPieChartData = {
    title: {
      text: 'Locations'
    },
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f} %</b><br>Number of Companies: <b>{point.y}</b>'
    },
    colors: ['#c0de91', '#e57c79', '#f6ca91', '#90d8cd', '#796d76', '#2d90cb'],
    series: [{
      name: null,
      colorByPoint: true,
      data: [
        {
          name: '1 Location',
          y: 75
        },
        {
          name: '2 Locations',
          y: 200
        },
        {
          name: '3 Locations',
          y: 120
        },
        {
          name: '4 Locations',
          y: 90
        },
        {
          name: '5 Locations',
          y: 120
        },
        {
          name: '6+ Locations',
          y: 130
        }
      ]
    }],
  };

  //coupons map
  const couponsMapData = {
    title: {
      text: 'Total Number of Coupons'
    },
    subtitle: {
      text: null
    },
    series: [{
      name: 'Total no. of coupons',
      states: {
        hover: {
          color: '#171f5f'
        }
      },
      dataLabels: {
        enabled: false,
      },
      allAreas: true,
      data: [
        ['fo', 0],
        ['um', 1],
        ['us', 2],
        ['jp', 3],
        ['sc', 4],
        ['in', 5],
        ['fr', 6],
        ['fm', 7],
        ['cn', 8],
        ['pt', 9],
        ['sw', 10],
        ['sh', 11],
        ['br', 12],
        ['ki', 13],
        ['ph', 14],
        ['mx', 15],
        ['es', 16],
        ['bu', 17],
        ['mv', 18],
        ['sp', 19],
        ['gb', 20],
        ['gr', 21],
        ['as', 22],
        ['dk', 23],
        ['gl', 24],
        ['gu', 25],
        ['mp', 26],
        ['pr', 27],
        ['vi', 28],
        ['ca', 29],
        ['st', 30],
        ['cv', 31],
        ['dm', 32],
        ['nl', 33],
        ['jm', 34],
        ['ws', 35],
        ['om', 36],
        ['vc', 37],
        ['tr', 38],
        ['bd', 39],
        ['lc', 40],
        ['nr', 41],
        ['no', 42],
        ['kn', 43],
        ['bh', 44],
        ['to', 45],
        ['fi', 46],
        ['id', 47],
        ['mu', 48],
        ['se', 49],
        ['tt', 50],
        ['my', 51],
        ['pa', 52],
        ['pw', 53],
        ['tv', 54],
        ['mh', 55],
        ['cl', 56],
        ['th', 57],
        ['gd', 58],
        ['ee', 59],
        ['ag', 60],
        ['tw', 61],
        ['bb', 62],
        ['it', 63],
        ['mt', 64],
        ['vu', 65],
        ['sg', 66],
        ['cy', 67],
        ['lk', 68],
        ['km', 69],
        ['fj', 70],
        ['ru', 71],
        ['va', 72],
        ['sm', 73],
        ['kz', 74],
        ['az', 75],
        ['tj', 76],
        ['ls', 77],
        ['uz', 78],
        ['ma', 79],
        ['co', 80],
        ['tl', 81],
        ['tz', 82],
        ['ar', 83],
        ['sa', 84],
        ['pk', 85],
        ['ye', 86],
        ['ae', 87],
        ['ke', 88],
        ['pe', 89],
        ['do', 90],
        ['ht', 91],
        ['pg', 92],
        ['ao', 93],
        ['kh', 94],
        ['vn', 95],
        ['mz', 96],
        ['cr', 97],
        ['bj', 98],
        ['ng', 99],
        ['ir', 100],
        ['sv', 101],
        ['sl', 102],
        ['gw', 103],
        ['hr', 104],
        ['bz', 105],
        ['za', 106],
        ['cf', 107],
        ['sd', 108],
        ['cd', 109],
        ['kw', 110],
        ['de', 111],
        ['be', 112],
        ['ie', 113],
        ['kp', 114],
        ['kr', 115],
        ['gy', 116],
        ['hn', 117],
        ['mm', 118],
        ['ga', 119],
        ['gq', 120],
        ['ni', 121],
        ['lv', 122],
        ['ug', 123],
        ['mw', 124],
        ['am', 125],
        ['sx', 126],
        ['tm', 127],
        ['zm', 128],
        ['nc', 129],
        ['mr', 130],
        ['dz', 131],
        ['lt', 132],
        ['et', 133],
        ['er', 134],
        ['gh', 135],
        ['si', 136],
        ['gt', 137],
        ['ba', 138],
        ['jo', 139],
        ['sy', 140],
        ['mc', 141],
        ['al', 142],
        ['uy', 143],
        ['cnm', 144],
        ['mn', 145],
        ['rw', 146],
        ['so', 147],
        ['bo', 148],
        ['cm', 149],
        ['cg', 150],
        ['eh', 151],
        ['rs', 152],
        ['me', 153],
        ['tg', 154],
        ['la', 155],
        ['af', 156],
        ['ua', 157],
        ['sk', 158],
        ['jk', 159],
        ['bg', 160],
        ['qa', 161],
        ['li', 162],
        ['at', 163],
        ['sz', 164],
        ['hu', 165],
        ['ro', 166],
        ['ne', 167],
        ['lu', 168],
        ['ad', 169],
        ['ci', 170],
        ['lr', 171],
        ['bn', 172],
        ['iq', 173],
        ['ge', 174],
        ['gm', 175],
        ['ch', 176],
        ['td', 177],
        ['kv', 178],
        ['lb', 179],
        ['dj', 180],
        ['bi', 181],
        ['sr', 182],
        ['il', 183],
        ['ml', 184],
        ['sn', 185],
        ['gn', 186],
        ['zw', 187],
        ['pl', 188],
        ['mk', 189],
        ['py', 190],
        ['by', 191],
        ['cz', 192],
        ['bf', 193],
        ['na', 194],
        ['ly', 195],
        ['tn', 196],
        ['bt', 197],
        ['md', 198],
        ['ss', 199],
        ['bw', 200],
        ['bs', 201],
        ['nz', 202],
        ['cu', 203],
        ['ec', 204],
        ['au', 205],
        ['ve', 206],
        ['sb', 207],
        ['mg', 208],
        ['is', 209],
        ['eg', 210],
        ['kg', 211],
        ['np', 212]
      ]
    }]
  };

  //companies map
  const companiesMapData = {
    title: {
      text: 'Total Number of Companies'
    },
    subtitle: {
      text: null
    },
    series: [{
      name: 'Total no. of companies',
      states: {
        hover: {
          color: '#171f5f'
        }
      },
      dataLabels: {
        enabled: false,
      },
      allAreas: true,
      data: [
        ['fo', 0],
        ['um', 1],
        ['us', 2],
        ['jp', 3],
        ['sc', 4],
        ['in', 5],
        ['fr', 6],
        ['fm', 7],
        ['cn', 8],
        ['pt', 9],
        ['sw', 10],
        ['sh', 11],
        ['br', 12],
        ['ki', 13],
        ['ph', 14],
        ['mx', 15],
        ['es', 16],
        ['bu', 17],
        ['mv', 18],
        ['sp', 19],
        ['gb', 20],
        ['gr', 21],
        ['as', 22],
        ['dk', 23],
        ['gl', 24],
        ['gu', 25],
        ['mp', 26],
        ['pr', 27],
        ['vi', 28],
        ['ca', 29],
        ['st', 30],
        ['cv', 31],
        ['dm', 32],
        ['nl', 33],
        ['jm', 34],
        ['ws', 35],
        ['om', 36],
        ['vc', 37],
        ['tr', 38],
        ['bd', 39],
        ['lc', 40],
        ['nr', 41],
        ['no', 42],
        ['kn', 43],
        ['bh', 44],
        ['to', 45],
        ['fi', 46],
        ['id', 47],
        ['mu', 48],
        ['se', 49],
        ['tt', 50],
        ['my', 51],
        ['pa', 52],
        ['pw', 53],
        ['tv', 54],
        ['mh', 55],
        ['cl', 56],
        ['th', 57],
        ['gd', 58],
        ['ee', 59],
        ['ag', 60],
        ['tw', 61],
        ['bb', 62],
        ['it', 63],
        ['mt', 64],
        ['vu', 65],
        ['sg', 66],
        ['cy', 67],
        ['lk', 68],
        ['km', 69],
        ['fj', 70],
        ['ru', 71],
        ['va', 72],
        ['sm', 73],
        ['kz', 74],
        ['az', 75],
        ['tj', 76],
        ['ls', 77],
        ['uz', 78],
        ['ma', 79],
        ['co', 80],
        ['tl', 81],
        ['tz', 82],
        ['ar', 83],
        ['sa', 84],
        ['pk', 85],
        ['ye', 86],
        ['ae', 87],
        ['ke', 88],
        ['pe', 89],
        ['do', 90],
        ['ht', 91],
        ['pg', 92],
        ['ao', 93],
        ['kh', 94],
        ['vn', 95],
        ['mz', 96],
        ['cr', 97],
        ['bj', 98],
        ['ng', 99],
        ['ir', 100],
        ['sv', 101],
        ['sl', 102],
        ['gw', 103],
        ['hr', 104],
        ['bz', 105],
        ['za', 106],
        ['cf', 107],
        ['sd', 108],
        ['cd', 109],
        ['kw', 110],
        ['de', 111],
        ['be', 112],
        ['ie', 113],
        ['kp', 114],
        ['kr', 115],
        ['gy', 116],
        ['hn', 117],
        ['mm', 118],
        ['ga', 119],
        ['gq', 120],
        ['ni', 121],
        ['lv', 122],
        ['ug', 123],
        ['mw', 124],
        ['am', 125],
        ['sx', 126],
        ['tm', 127],
        ['zm', 128],
        ['nc', 129],
        ['mr', 130],
        ['dz', 131],
        ['lt', 132],
        ['et', 133],
        ['er', 134],
        ['gh', 135],
        ['si', 136],
        ['gt', 137],
        ['ba', 138],
        ['jo', 139],
        ['sy', 140],
        ['mc', 141],
        ['al', 142],
        ['uy', 143],
        ['cnm', 144],
        ['mn', 145],
        ['rw', 146],
        ['so', 147],
        ['bo', 148],
        ['cm', 149],
        ['cg', 150],
        ['eh', 151],
        ['rs', 152],
        ['me', 153],
        ['tg', 154],
        ['la', 155],
        ['af', 156],
        ['ua', 157],
        ['sk', 158],
        ['jk', 159],
        ['bg', 160],
        ['qa', 161],
        ['li', 162],
        ['at', 163],
        ['sz', 164],
        ['hu', 165],
        ['ro', 166],
        ['ne', 167],
        ['lu', 168],
        ['ad', 169],
        ['ci', 170],
        ['lr', 171],
        ['bn', 172],
        ['iq', 173],
        ['ge', 174],
        ['gm', 175],
        ['ch', 176],
        ['td', 177],
        ['kv', 178],
        ['lb', 179],
        ['dj', 180],
        ['bi', 181],
        ['sr', 182],
        ['il', 183],
        ['ml', 184],
        ['sn', 185],
        ['gn', 186],
        ['zw', 187],
        ['pl', 188],
        ['mk', 189],
        ['py', 190],
        ['by', 191],
        ['cz', 192],
        ['bf', 193],
        ['na', 194],
        ['ly', 195],
        ['tn', 196],
        ['bt', 197],
        ['md', 198],
        ['ss', 199],
        ['bw', 200],
        ['bs', 201],
        ['nz', 202],
        ['cu', 203],
        ['ec', 204],
        ['au', 205],
        ['ve', 206],
        ['sb', 207],
        ['mg', 208],
        ['is', 209],
        ['eg', 210],
        ['kg', 211],
        ['np', 212]
      ]
    }]
  };


  //coupons Pie Chart
  const couponsPieChart = Object.assign(couponsPieChartData, pieChart);

  //Locations Pie Chart
  const locationsPieChart = Object.assign(locationsPieChartData, pieChart);

  //registrations Line Graph
  const registrationsLineGraph = Object.assign(registrationsLineGraphData, LineGraph);

  //coupons map
  const couponsMap = Object.assign(couponsMapData, highMap);

  //companies map
  const companiesMap = Object.assign(companiesMapData, highMap);







  return (
    <div className="overview-container" ref={divRef} >
      <div className="overview">
        <div className="highchart-container" style={{ width: `${size.width - 250}px` }} >
          <div className="overview-details">
            <div className="card">
              <div className="card-details">
                <span className='card-number' >893</span>
                <span className='card-title' >Total Number of Companies</span>
              </div>
              <div className="card-img">
                <img src={CompanyIcon} alt="icon" />
              </div>
            </div>
            <div className="card">
              <div className="card-details">
                <span className='card-number' >141</span>
                <span className='card-title' >Total Number of Coupons</span>
              </div>
              <div className="card-img">
                <img src={CouponIcon} alt="icon" />
              </div>
            </div>
            <div className="card">
              <div className="card-details">
                <span className='card-number' >15.79 %</span>
                <span className='card-title' >Conversion Rate</span>
              </div>
              <div className="card-img">
                <img src={UsersIcon} alt="icon" />
              </div>
            </div>

          </div>

          <div className="overview-charts">
            <div className="chart-full-width">
              <div className="chart-content">
                <HighchartsReact containerProps={{ className: 'chartContainer' }} highcharts={Highcharts} options={registrationsLineGraph} />
              </div>
            </div>
          </div>

          <div className="overview-charts flex-gap">
            <div className="chart-half-width">
              <div className="chart-content">
                <HighchartsReact containerProps={{ className: 'chartContainer' }} highcharts={Highcharts} options={couponsPieChart} />
              </div>
            </div>
            <div className="chart-half-width">
              <div className="chart-content">
                <HighchartsReact containerProps={{ className: 'chartContainer' }} highcharts={Highcharts} options={locationsPieChart} />
              </div>
            </div>
          </div>

          <div className="overview-charts ">
            <div className="chart-full-width">
              <div className="chart-content">
                <HighchartsReact
                  containerProps={{ className: 'chartContainer' }}
                  constructorType={'mapChart'}
                  highcharts={Highcharts}
                  options={couponsMap} />
              </div>
            </div>
          </div>


          <div className="overview-charts">
            <div className="chart-full-width">
              <div className="chart-content">
                <HighchartsReact
                  containerProps={{ className: 'chartContainer' }}
                  constructorType={'mapChart'}
                  highcharts={Highcharts}
                  options={companiesMap} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Overview;