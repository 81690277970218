import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';


import AuthContext from '../../context/AuthContext';
import ModalContext from '../../context/ModalContext.js';
import DrawerContext from '../../context/DrawerContext.js';

//assets
import placeholder from '../../assets/img/blog/placeholder.png';
import editButton from '../../assets/img/blog/edit.svg';
import deleteButton from '../../assets/img/blog/delete.svg';

import { Button, CircularProgress, Card } from '@mui/material'
import { AddRounded } from '@mui/icons-material';
import CustomButton from '../../common/CustomButton.jsx';

const Blogs = () => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const navigate = useNavigate();

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [blogs, setBlogs] = useState([])
  const [blogPage, setBlogPage] = useState(1)

  // updating status states 
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [loadingBlogs, setLoadingStatus] = useState(true);
  const [updatingStatusId, setUpdatingStatusId] = useState();

  useEffect(() => {
    getAllBlogs()
  }, []);

  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  const getAllBlogs = () => {    

    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/blogs?page=${blogPage}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          setBlogs(data?.data.reverse())
          setUpdatingStatus(false)
          setLoadingStatus(false)
        }
      }).catch(err => {
        console.error(err)
        setUpdatingStatus(false)
      });

  }


  const getStatusClass = (id) => {
    let blog = blogs?.find(obj => obj.id == id);
    if (blog && blog.status == 'published') {
      return 'author-tag published';
    } else {
      return 'author-tag draft';
    }
  }

  const postStatusClass = (id) => {
    let blog = blogs?.find(obj => obj.id == id);
    if (blog && blog.status == 'published') {
      return 'post-status published';
    } else {
      return 'post-status draft';
    }
  }
  function showRegDate(unFormattedDate) {

    const date = new Date(unFormattedDate);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };

    return date.toLocaleDateString('en-US', options);
  }

  const openDeleteDevotionalModal = (id) => {
    modalCtx.setDetails('confirm-delete-blog', { id });
    modalCtx.openModal();
  }
  const openPublishModal = (blog) => {
    modalCtx.setDetails('confirm-publish-blog', { blog });
    modalCtx.openModal();
  }

  const openUploadImageModal = (id) => {
    modalCtx.setDetails('upload-fixed-image', { aspectRatio: 1.6, origin: 'blog', id: id });
    modalCtx.openModal();
  }

  console.log(blogs)

  return (

    <div className="blogs-page">

      <header className="template-header" >
        <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Blogs &nbsp; {loadingBlogs ? <CircularProgress size={22} /> : null}</h1>
        <div>
          <CustomButton data-aos="fade-right" data-aos-delay="400" onClick={() => { toggleDrawerHandler('create-blog', null) }}>Create New</CustomButton>
        </div>
      </header>

      <section className='template-section' >
        <div className='blogs'>
          {
            !blogs?.length
              ? <div className="no-blogs">No Blogs</div>
              : <> {
                blogs?.map((blog, index) => (
                  <Card className='blog-card' key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <div className={postStatusClass(blog.id)}></div>

                    {
                      blog.image
                        ?
                        <div className='blog-img-container' onClick={() => openUploadImageModal(blog.id)}>
                          <img src={blog.image} alt='blog' className='devotional-img bg-image' />
                        </div>
                        :
                        <div className='placeholder-img' onClick={() => openUploadImageModal(blog.id)}>
                          <img src={placeholder} alt="placeholder" />
                          <span className='placeholder-text' >No image. Click to Add a image</span>
                        </div>
                    }

                    <div align="left" className='blog-title'>

                      <p className='blog-title-text' >{blog.title}</p>
                      <p className='blog-title-date' ><span>Post Date -</span>{showRegDate(blog.created_at)}</p>
                      <p className='blog-title-text' >{blog.time}</p>

                      <div className='tags-row'>
                        <div className={'tag'} style={blog.authors?.length > 0 ? { background: '#f1f1f1', color:'#222', border: '1px solid #666' } : null} onClick={() => handleModal('edit-blog-author', blog)}>
                          
                          {blog.authors?.length > 0 ? null : <AddRounded className='add-tag action-icon' />}
                          {blog.authors?.length > 0 ? blog.authors[0]?.fullName : 'Author'}
                          {/* {blog.authors?.length > 1 ? <div className='number-icon'>{blog.authors?.length}</div> : null} */}
                        </div>
                        <div className='tag' onClick={() => handleModal('edit-blog-topics', blog)} >
                          <AddRounded className='add-tag action-icon' />
                          Topic
                          {blog.topics?.length > 1 ? <div className='number-icon'>{blog.topics?.length}</div> : null}
                        </div>
                        <div className='tag' onClick={() => handleModal('edit-blog-tags', blog)} >
                          <AddRounded className='add-tag action-icon' />
                          Tag
                          {blog.tags?.length > 1 ? <div className='number-icon'>{blog.tags?.length}</div> : null}
                        </div>
                      </div>

                      <div className='engaments-row'>
                        <p className='blog-title-date' ><span>Likes - </span>{blog.time}0 Likes</p>
                        <p className='blog-title-date' ><span>Comments - </span>{blog.time}0 Comments</p>
                      </div>

                      <div className='editable-row'>
                        <div className='status-row'>
                          <span>Status -</span>
                          <div className="author-tags" onClick={() => openPublishModal(blog)}>
                            {
                              (updatingStatus && updatingStatusId == blog.id) ?
                                <CircularProgress size={20} />
                                :
                                <span className={getStatusClass(blog.id)}>{blog.status.toUpperCase()}</span>
                            }
                          </div>
                        </div>
                        <div className='edit-btns df-center'>
                          <div className='df-center edit-btn' onClick={() => toggleDrawerHandler('edit-blog', blog)}><img src={editButton} alt='button'></img></div>
                          <div className='df-center edit-btn' onClick={() => openDeleteDevotionalModal(blog.id)}><img src={deleteButton} alt='button'></img></div>
                        </div>
                      </div>

                    </div>

                  </Card>
                ))
              }
              </>
          }

        </div>
      </section>
    </div>
  )
}

export default Blogs;
