import { useContext } from 'react';
import { Button } from '@mui/material';
import AuthContext from '../../../context/AuthContext';
import ModalContext from '../../../context/ModalContext';

const DeleteTagModal = ({ modalDetails }) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const handleCloseModal = () => {
    modalCtx.closeModal();
  }

  const deleteTag = (id) => {
    if (authCtx.isLoggedIn) {
      fetch(`${process.env.REACT_APP_API_URI}/v3/admin/tag/${id}`, {
        method: 'DELETE',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status == "success") {
          modalCtx.closeModal();
        } else {
          console.error("Error while deleting tag...");
        }
      }).catch((err) => {
        console.error("Error while deleting tag...", err);
      });
    }
  }

  return (
    <div className="delete-modal">
      <p>Are you sure you want to delete this Tag?</p>
      <div className="footer">
        <button className="btn-primary-border" onClick={(e) => { handleCloseModal() }}>No</button>
        <button className="btn-primary" onClick={() => deleteTag(modalDetails.id)}>Yes</button>
      </div>
    </div>
  )
}

export default DeleteTagModal;
