import React, { useState, useEffect, useCallback } from 'react';
import { signInWithEmailAndPassword, signOut, onIdTokenChanged, createUserWithEmailAndPassword } from 'firebase/auth';

import { auth } from "./Firebase"
// import { ref, set, update } from "firebase/database";
// import { database } from "../Components/FirebaseConfig";
import { database } from "./Firebase";

const AuthContext = React.createContext({
  token: '',
  userId: '',
  userName: '',
  displayName: '',
  email: '',
  avatarRef: '',
  isLoggedIn: false,
  login: (token, userId, userName, email, scope, tokenExpiration) => {},
  logout: () => {}
});

export const AuthContextProvider = (props) => {

  //firebase db
  const db = database;

  const initialToken                  = localStorage.getItem('delivaToken');
  const initialUserId                 = localStorage.getItem('userId');
  const initialUserName               = localStorage.getItem('userName');
  const initialDisplayName            = localStorage.getItem('displayName');
  const initialEmail                  = localStorage.getItem('email');
  const initialAvatarRef              = localStorage.getItem('avatarRef');

  const [token, setToken]             = useState(initialToken);
  const [userId, setUserId]           = useState(initialUserId);
  const [userName, setUserName]       = useState(initialUserName);
  const [displayName, setDisplayName] = useState(initialDisplayName);
  const [email, setEmail]             = useState(initialEmail);
  const [avatarRef, setAvatarRef]     = useState(initialAvatarRef);
  const [error,setError] = useState("no error")

  const userIsLoggedIn                = !!token;  

  useEffect(() => {
    onIdTokenChanged(auth, data => {
      if(data) {
        setToken(data.accessToken);
        setUserId(data.uid);
        localStorage.setItem('delivaToken', data.accessToken);
        localStorage.setItem('userId', data.uid);
      }
    })
  }, [onIdTokenChanged, setToken, setUserId]);

  const loginHandler = (email, password, userName, displayName, avatarRef) => {

    signInWithEmailAndPassword(auth, email, password)
    .then((data) => {
      
      setToken(data.user.accessToken);
      setUserId(data.user.uid);
      setUserName(userName);
      setDisplayName(displayName);
      setEmail(email);
      setAvatarRef(avatarRef);
      setError("no error")

      localStorage.setItem('delivaToken', data.user.accessToken);
      localStorage.setItem('userId', data.user.uid);
      localStorage.setItem('userName', userName);
      localStorage.setItem('displayName', displayName);
      localStorage.setItem('email', email);
      localStorage.setItem('avatarRef', avatarRef);

    })
    .catch((error) => {
      console.log(error);
      setError(error)
    });

  }

  const logoutHandler = () => {

    signOut(auth)
    .then(() => {
      console.log("Signed out");
      setToken(null);
      setUserId(null);
      setUserName(null);
      setDisplayName(null);
      setEmail(null);
      setAvatarRef(null);

      localStorage.removeItem('delivaToken');
      localStorage.removeItem('userId');
      localStorage.removeItem('userName');
      localStorage.removeItem('displayName');
      localStorage.removeItem('email');
      localStorage.removeItem('avatarRef');
    })
    .catch((error) => {
      console.log(error);
    });
    
  };

  const createUserHandler = async (email, password, name)=>{

    createUserWithEmailAndPassword(auth, email.trim(), password, name)
      .then((data) => {

        const uid = data.user.uid
        const tempToken = data.user.accessToken

        var userPayload = {
          firstName:"test", 
          lastName:"test", 
          email: email, 
          password: password
        };
        console.log(userPayload)

        fetch(process.env.REACT_APP_API_URI + `/v1/register`, {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tempToken}` },
          body: JSON.stringify(userPayload)
        }).then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == "success") {

            // set(ref(db, `users/${uid}`), { "email": email })
            //   .then(() => {
            //     console.log('user added')
            //     getUserData(uid, tempToken)
            //   })
            //   .catch((error) => {
            //     console.log(error)
            //   });

          } else {
            console.log('something went wrong while adding user')
          }
        });
      })
      .catch((e) => {
        console.log(e);
        console.log(e.code);
        if (e.code === 'weak-password') {
          console.log("The password provided is too weak.")
        } else if (e.code === 'email-already-in-use') {
          console.log("The account already exists for that email.")
        } else if (e.code === 'too-many-requests') {
          console.log("Sorry, failed too many signup attempts. Please try again later")
        }
      });
  }

  const getUserData = (uid, tempToken) => {

    console.log(uid, tempToken)

    // fetch(process.env.REACT_APP_API_URI + `/v1/user/${uid}`, {
    //   method: 'GET',
    //   mode: 'cors',
    //   headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tempToken}` },
    // }).then(response => {
    //   return response.json();
    // }).then(data => {
    //   console.log(data);
    //   if(data.status == "success") {
    //     setToken(tempToken);
    //     setUserId(data.data.id);
    //     setUserName(data.data.name);
    //     setDisplayName(data.data.username);
    //     setEmail(data.data.email);
    //     setAvatarRef(data.data.avatarRef);
  
    //     localStorage.setItem('token', tempToken);
    //     localStorage.setItem('userId', data.data.id);
    //     localStorage.setItem('userName', data.data.username);
    //     localStorage.setItem('displayName', data.data.name);
    //     localStorage.setItem('email', data.data.email);
    //     localStorage.setItem('avatarRef', data.data.avatarRef);
  
    //   } else {
    //     console.log('something went wrong while getting user')
    //   }
    // });

  }

  const contextValue = {
    token: token,
    userId: userId,
    userName: userName,
    displayName: displayName,
    email: email,
    avatarRef: avatarRef,
    isLoggedIn: userIsLoggedIn,
    error: error,
    login: loginHandler,
    logout: logoutHandler,
    createUser : createUserHandler
  }
  console.log(contextValue);
  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
}

export default AuthContext;