import React from 'react'

const TextInput = (props) => {
  const { disabled, label, name, type, value, placeholder, className, error, onKeyUp, onChange, onClick, ...other } = props;
  return (
    <>
      <div style={label ? { height: '85px' } : null} className={className ? `${className} text-input` : "text-input"} >
        {label ? <div className="form-label">{label}</div> : null}
        <div className="form-input" style={error ? { border: '2px solid #D63F15' } : null}>
          <input
            type={type ? type : "text"}
            value={value ? value : ''}
            name={name ? name : null}
            disabled={disabled ? disabled : false}
            onKeyUp={onKeyUp}
            onChange={onChange}
            onClick={onClick}
            placeholder={placeholder ? placeholder : ''} 
            {...other}
            />
          {/* {error ? <img src="/img/error.svg" height={25} width={25} alt="error" className="error-img" /> : null} */}
        </div>
        {/* {
          error
            ? <div className='form-error'>{error}</div>
            : null
        } */}
      </div>
    </>
  )
}

export default TextInput