import React, { useContext, useEffect, useState } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';

import DrawerContext from '../../../context/DrawerContext';
import AuthContext from '../../../context/AuthContext';
import CustomButton from '../../../common/CustomButton';


function AddCategory({ drawerDetails }) {

  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);

  const [categoryName, setCategoryName] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isMainCategoryChecked, setIsMainCategoryChecked] = useState(false);
  const [mainCategory, setMainCategory] = React.useState('');

  const [categories, setCategories] = useState([]);

  const getAllCategories = () => {

    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URI}/v2/categories`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
    .then(response => {
      return response.json();
    }).then(data => {
      if (data.status == 'success') {
        setLoading(false)
        setCategories(data?.data);
      }
    }).catch(err => {
      console.error(err)
    });

  }

  

  const handleChange = (event) => {
    setMainCategory(event.target.value);
  };

  useEffect(() => {
    getAllCategories();
    if (drawerDetails.type.toLowerCase() == "edit") {

      setCategoryName(drawerDetails?.value.title);
      setFile(drawerDetails?.value.icon);

      if (drawerDetails.priority == "sub") {

        setMainCategory(drawerDetails?.value.categoryId);

      }

    }
  }, [drawerDetails]);

  const createCategory = () => {

    setIsSaving(true);

    try {
      fetch(`${process.env.REACT_APP_API_URI}/v3/admin/category`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
        body: JSON.stringify({
          isMainCat: isMainCategoryChecked,
          categoryTitle: categoryName,
          mainCategorySelected: mainCategory,
        }),
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          drawerCtx.setPageUpdate({page: "categories"});
          drawerCtx.closeDrawer();
          setIsSaving(false);
        }
      });
    } catch (error) {
      console.log("error : ", error);
    }

  }
  const handleCheckboxChange = (e) => {
    if(!isMainCategoryChecked){
      setMainCategory("Main Category")
    }
    setIsMainCategoryChecked(e.target.checked);
  };

  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 style={{}} data-aos="fade-right" data-aos-delay="500">CREATE CATEGORY</h1>
        </header>

        <section className='add-category-content' >

          {/* <div className='icon-div'>
            <span>Icon:</span> <Avatar src={file ? drawerDetails.type.toLowerCase() == "edit" ? file : URL.createObjectURL(file):null} alt="Remy Sharp" sx={{ width: 100, height: 100 }} >{categoryName ? capitalize(categoryName[0]) : null}</Avatar>
            <input type="file" onChangeCapture={onFileChangeCapture}  />
          </div> */}

          <h3>Create a new <strong>Coupon Category</strong></h3><br/>
          
          <label htmlFor='maincatgory' className='maincatgory'>
            <input type="checkbox" id='maincatgory' checked={isMainCategoryChecked} onChange={handleCheckboxChange} />  Add as Main Category
          </label>
          <br/><br/>

          {
            !isMainCategoryChecked ? <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Main Category</InputLabel>
              <Select labelId="demo-simple-select-label" id="demo-simple-select" value={mainCategory} label="Select Main Category" onChange={handleChange}>
                {categories?.map((category, index) => {
                  return <MenuItem key={index} value={category.id}>{category.title}</MenuItem>
                })}
              </Select>
            </FormControl> : <>
              <TextField id="outlined-basic" fullWidth label={"Main Category Title"} value={categoryName} onChange={(e) => setCategoryName(e.target.value)} />
              <p className='warning'>
                <span>Warning:</span> New main categories must be added to the mobile app during the build process.
              </p>
            </>
          }

          <br/><br/>

          {
            !isMainCategoryChecked && 
            <TextField id="outlined-basic" fullWidth label={drawerDetails.type == "create" ? "Sub Category Name" : "Main Category Name"} value={categoryName} onChange={(e) => setCategoryName(e.target.value)} />
          }

        </section>

        <footer>
          <CustomButton onClick={() => drawerCtx.closeDrawer()} className="cancel">Cancel</CustomButton>
          <CustomButton onClick={() => createCategory()} variant="contained" disabled={categoryName.length == 0}>
            {isSaving ? <CircularProgress style={{ color: "#fff" }} size={20} /> : "Create Category" }
          </CustomButton>
        </footer>

      </div>
    </div>
  );

}
export default AddCategory;

