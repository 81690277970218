import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Card, Checkbox, Chip, CircularProgress, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../../context/AuthContext';
import { CheckBox, Close, ExpandMore } from '@mui/icons-material';

export default function CouponDetails({ couponDetails, setCouponDetails, orderedCategoriesData, setOrderedCategoriesData, errors, setError }) {

  const authCtx = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [currentKeyword, setKeyword] = useState('');
  const [accorrdionDetails, setAccordionDetails] = useState([]);
  const [expandId, setExpanId] = useState(null);
  const [companyIncluded, setCompanyIncluded] = useState(false)

  useEffect(() => {
    const categoryListWithId = categoriesData?.flatMap((option) =>
      option.children.map((child) => {
        return { id: child.id, parent: child.parent, title: child.title }
      })
    );
    setOrderedCategoriesData(categoryListWithId);
  }, [categoriesData])


  useEffect(() => {
    getAllCategories();
    getAllCountries();
  }, [])

  const getAllCategories = () => {
    try {
      setLoading(true);
      fetch(`${process.env.REACT_APP_API_URI}/v3/categories`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      }).then(response => {
        return response.json();
      }).then(data => {
        setLoading(false);
        if (data.status == 'success') {
          const parentItem = data?.data?.filter((item) => item?.parent == 0);

          const childItem = data?.data?.filter((item) => item?.parent != 0);

          const parentWithChildren = parentItem.map((parent) => {
            const children = childItem.filter((child) => child.parent === parent.id);
            return { parent, children };
          });
          setCategoriesData(parentWithChildren);
        }
      })
    } catch (err) {
      console.error(err)
    }
  }
  const getAllCountries = () => {
    setLoading(true);
    try {
      fetch(`${process.env.REACT_APP_API_URI}/v3/admin/companies/countries`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          setLoading(false);
          setCountriesList(data?.data);
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  const selectCouponCategories = (_couponCategories) => {
    setError('categories', false);
    setCouponDetails({ ...couponDetails, categories: _couponCategories })
  }
  const setCouponClaimUrl = (_url) => {
    setCouponDetails({ ...couponDetails, couponClaimUrl: _url })
  }
  const selectCountries = (_countries) => {
    setCouponDetails({ ...couponDetails, countries: _countries })
  }
  const setPhoneNumber = (_phone) => {
    setCouponDetails({ ...couponDetails, phoneNumber: _phone })
  }
  const setEmail = (_email) => {
    setCouponDetails({ ...couponDetails, emailAddress: _email })
  }
  const selectKeywords = (_keywords) => {
    setCouponDetails({ ...couponDetails, keywords: _keywords })
  }
  const setCouponCode = (_couponCode) => {
    setCouponDetails({ ...couponDetails, couponCode: _couponCode })
  }

  const handleOnChangeCategoryAutoComplete = (event, _newValue) => {
    setError('categories', false);
    const ids = _newValue.map(option => option.id);
    selectCouponCategories([...new Set(ids)]);
  };

  const handleKeyboardSubmit = (e) => {
    e.preventDefault();
    setCouponDetails({ ...couponDetails, keywords: [...couponDetails.keywords, currentKeyword] });
    setKeyword('');
  }

  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanId(newExpanded ? panel : null);
    if(!newExpanded) setAccordionDetails([]);
  };

  return (
    <div className='create-coupon-section-v2'>
      <div className='vertical-container'>
        <div className='horizontal-row-card-container vertical'>

          {loading ? <CircularProgress /> : <>
            <Card className='card-container' sx={{ width: '100%' }}>
              <Autocomplete
                limitTags={3}
                fullWidth
                multiple
                options={orderedCategoriesData}
                getOptionLabel={(option) => option.title}
                value={couponDetails?.categories?.map(id => orderedCategoriesData?.find(option => option.id == id))}
                onChange={handleOnChangeCategoryAutoComplete}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      variant="outlined"
                      label={option?.title}
                      {...getTagProps({ index })}
                      style={{
                        backgroundColor: '#2b1de8',
                        color: 'white',
                        fontWeight: 'bold',
                        border: 'none',
                      }}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder="Select Categories"
                  />
                )}
              />
              {errors?.categories?.show && <div className="coupon-error-text">{errors.categories.message}</div>}
              <span className='subtitle full-width'>Only 3 categories are allowed.</span>
            </Card>
            <Card className='card-container' sx={{ width: '100%', maxHeight: "500px", overflowY: 'auto', padding: "0rem !important", gap: "0rem !important" }}>
              {categoriesData.map((item, index1) => <Accordion 
                  key={index1} sx={{ width: "100%" }} expanded={expandId === `${item.parent.title}-index${index1}`} onChange={handlePanelChange(`${item.parent.title}-index${index1}`)} >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                  sx={{ gap: '0rem' }}
                  onClick={() => {
                    setAccordionDetails(item.children);
                  }}
                >
                  {item.parent.title}
                </AccordionSummary>
                <AccordionDetails>
                  {accorrdionDetails && accorrdionDetails.length > 0 && accorrdionDetails.map((option, index2) =>
                    <div key={option.title + index1 + index2}
                      className='flex-row-container'
                    >
                      <span>{option.title}</span>
                      <Button onClick={() => {
                        selectCouponCategories([...new Set([...couponDetails.categories, option.id])])
                      }} sx={{ color: "#2b1de8" }}>+ Add</Button>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>)}
            </Card></>}
        </div>
        <div className='horizontal-row-card-container vertical'>

          <Card className='card-container' sx={{ width: '100%' }}>
            <span className='title'>Details to claim coupon</span>
            <div className='mutiple-text-field-container'>
              <div className='textfields-container'>
                <TextField
                  id="standard-multiline-static"
                  label="Coupon Website (URL where coupon can be claimed)"
                  value={couponDetails.couponClaimUrl}
                  onChange={(e) => setCouponClaimUrl(e.target.value)}
                  fullWidth
                  placeholder='Coupon Website URL it applicable(include full address with http:// or https://)'
                  variant="standard"
                  onFocus={() => setError("website", false)}
                />
                {errors?.website?.show && <div className="coupon-error-text">{errors.website.message}</div>}
                <Autocomplete
                  fullWidth
                  multiple
                  id="tags-filled"
                  options={countriesList?.map((option) => option.name)}
                  value={couponDetails.countries}
                  onChange={(e, value) => {
                    selectCountries(value);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  onFocus={() => setError("countries", false)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="This coupon is available in the following countries"
                    // placeholder="Favorites"
                    />
                  )}
                />
                {errors?.countries?.show && <div className="coupon-error-text">{errors.countries.message}</div>}
                <TextField
                  id="standard-multiline-static"
                  // defaultValue={""}
                  value={couponDetails.phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  fullWidth
                  onFocus={() => setError('phone', false)}
                  placeholder='Phone / Mobile Number'
                  variant="standard"
                />
                {errors?.phone?.show && <div className="coupon-error-text">{errors.phone.message}</div>}
                <TextField
                  id="standard-multiline-static"
                  value={couponDetails.emailAddress}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  onFocus={() => setError('mail', false)}
                  placeholder='Email Address'
                  variant="standard"
                />
                {errors?.mail?.show && <div className="coupon-error-text">{errors.mail.message}</div>}

              </div>
              <img src='https://admin.delivait.com/assets/img/add-terms.png' />

            </div>

            <div className='single-text-field-container'>
              <form onSubmit={handleKeyboardSubmit}>
                <TextField
                  id="standard-multiline-static"
                  label="Keywords"
                  fullWidth
                  placeholder='press enter to add a keyword'
                  value={currentKeyword}
                  onChange={(e) =>
                    setKeyword(e.target.value)
                  }
                  variant="standard"
                  onFocus={() => setError('keywords', false)}
                />

              </form>

              <span className='subtitle checkbox-container'><Checkbox checked={companyIncluded} onChange={() => setCompanyIncluded(incl => !incl)} sx={{
              '& .MuiSvgIcon-root': { fontSize: 30 }
            }} />
                Add this to the company keywords too
              </span>
            </div>

            <div className='keyword-list'>

              {couponDetails?.keywords?.map((keyword, index) => <div key={keyword + index} className='keywordBatch'>
                <span>{keyword}</span>
                <Close onClick={() => {
                  selectKeywords(couponDetails.keywords.filter((item) => item != keyword))
                }} />
              </div>)}
            </div>

            {errors?.keywords?.show && <div className="coupon-error-text">{errors.keywords.message}</div>}


          </Card>

          <Card className='card-container' sx={{ width: '100%' }}>
            <span className='title'>Coupon Code</span>

            <div className='single-text-field-container'>
              <TextField
                id="standard-multiline-static"
                label="Enter a code for your coupon.."
                value={couponDetails.couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                // defaultValue={""}
                fullWidth
                onFocus={() => setError('code', false)}
                placeholder='i.e 4AEGAE86'
                variant="standard"
              />

              <img src='https://admin.delivait.com/assets/img/add-terms.png' />
            </div>

            {errors?.code?.show && <div className="coupon-error-text">{errors.code.message}</div>}
          </Card>
        </div>
      </div>


    </div>
  )
}
