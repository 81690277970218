import { initializeApp } from "firebase/app";
import { getStorage, uploadBytes, ref, uploadString, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"

// Your web app's Firebase configuration
const app = initializeApp({
  apiKey: "AIzaSyBDxzT4R7OVIu-rom3Pq1KNjTEOBLr2758",
  authDomain: "deliva.firebaseapp.com",
  databaseURL: "https://deliva.firebaseio.com",
  projectId: "deliva",
  storageBucket: "deliva.appspot.com",
  messagingSenderId: "353810213624",
  appId: "1:353810213624:web:c17dff054c89da01cf690d",
  measurementId: "G-BK3RY4LYC1"
});


// export default app

// const storage = getStorage(app);
const database = getDatabase(app);
const firestore = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
export { database, firestore, uploadBytes, ref, uploadString, uploadBytesResumable , getDownloadURL };

export default app;