import React, { useContext, useState, useEffect } from 'react';

import ModalContext from '../../../context/ModalContext.js';
import AuthContext from '../../../context/AuthContext.js';
import { CloseRounded } from '@mui/icons-material';
import { Button, MenuItem, Select, TextField } from '@mui/material';

const CouponCreatedModal = ({ modalDetails }) => {



    console.log(modalDetails);
    const modalCtx = useContext(ModalContext);

    const closeHandle = () => {
        modalCtx.closeModal();
    }

    const [agent, setAgent] = React.useState('');

    const handleChange = (event) => {
        setAgent(event.target.value);
    };

    return (
        <>
            <div className="create-devotional-container create-coupon-modal">
                <div className='coupon-modal-header'>
                    <CloseRounded className='close' onClick={() => closeHandle()} />
                </div>
                <div className="body-container coupon-body-container">
                    <img src={"https://admin.delivait.com/assets/img/ok_icon_green.png"}></img>
                    <h1>Coupon created!</h1>
                    <span>The administration team will review
                        the coupon and inform you when approved.</span>
                    <Button style={{ color: "#555" }} onClick={()=>{
                        closeHandle();
                        window.location.replace("/coupons");
                    }} >Ok Got it!</Button>

                </div>

            </div>
        </>
    )
}

export default CouponCreatedModal;
