// react
import { Button } from '@mui/material';
import React from 'react';
import CustomButton from '../../common/CustomButton';


const Resources = () => {


  return (
    <div className="resource-container">
      <div className="resource">
        <div className='resource-header'>
          <h1 className="title">Resources</h1>
          <CustomButton >+ Add Resource</CustomButton>
        </div>
        <div className="resource-page">
          <div className="card">
            <div className="card-header-text">
              <span className="resource-title mat-card-title">Sales Pitch for Coupon Adverts</span>
            </div>
            <div className="card-image" >
              <img src="https://deliva-assets.s3.amazonaws.com/97bb6d59c8e93d81606f5f942c6c4b3f" alt="Sales Pitch for Coupon Adverts" />
            </div>
            <div className="card-content">
              <p> Training document for selling ads on DELIVA platform </p>
            </div>
            <div className="card-actions">
              <CustomButton >View &amp; Download</CustomButton>
              <CustomButton >Edit</CustomButton>
              <CustomButton className="deliva-btn-bordered">Delete</CustomButton>
            </div>
          </div>
          <div className="card">
            <div className="card-header-text">
              <span className="resource-title mat-card-title">Sales Pitch for Coupon Adverts</span>
            </div>
            <div className="card-image" >
              <img src="https://deliva-assets.s3.amazonaws.com/97bb6d59c8e93d81606f5f942c6c4b3f" alt="Sales Pitch for Coupon Adverts" />
            </div>
            <div className="card-content">
              <p> Training document for selling ads on DELIVA platform </p>
            </div>
            <div className="card-actions">
              <CustomButton >View &amp; Download</CustomButton>
              <CustomButton >Edit</CustomButton>
              <CustomButton className="deliva-btn-bordered">Delete</CustomButton>
            </div>
          </div>
          <div className="card">
            <div className="card-header-text">
              <span className="resource-title mat-card-title">Sales Pitch for Coupon Adverts</span>
            </div>
            <div className="card-image" >
              <img src="https://deliva-assets.s3.amazonaws.com/97bb6d59c8e93d81606f5f942c6c4b3f" alt="Sales Pitch for Coupon Adverts" />
            </div>
            <div className="card-content">
              <p> Training document for selling ads on DELIVA platform </p>
            </div>
            <div className="card-actions">
              <CustomButton >View &amp; Download</CustomButton>
              <CustomButton >Edit</CustomButton>
              <CustomButton className="deliva-btn-bordered">Delete</CustomButton>
            </div>
          </div>
          <div className="card">
            <div className="card-header-text">
              <span className="resource-title mat-card-title">Sales Pitch for Coupon Adverts</span>
            </div>
            <div className="card-image" >
              <img src="https://deliva-assets.s3.amazonaws.com/97bb6d59c8e93d81606f5f942c6c4b3f" alt="Sales Pitch for Coupon Adverts" />
            </div>
            <div className="card-content">
              <p> Training document for selling ads on DELIVA platform </p>
            </div>
            <div className="card-actions">
              <CustomButton >View &amp; Download</CustomButton>
              <CustomButton >Edit</CustomButton>
              <CustomButton className="deliva-btn-bordered">Delete</CustomButton>
            </div>
          </div>
          <div className="card">
            <div className="card-header-text">
              <span className="resource-title mat-card-title">Sales Pitch for Coupon Adverts</span>
            </div>
            <div className="card-image" >
              <img src="https://deliva-assets.s3.amazonaws.com/97bb6d59c8e93d81606f5f942c6c4b3f" alt="Sales Pitch for Coupon Adverts" />
            </div>
            <div className="card-content">
              <p> Training document for selling ads on DELIVA platform </p>
            </div>
            <div className="card-actions">
              <CustomButton >View &amp; Download</CustomButton>
              <CustomButton >Edit</CustomButton>
              <CustomButton className="deliva-btn-bordered">Delete</CustomButton>
            </div>
          </div>
          <div className="card">
            <div className="card-header-text">
              <span className="resource-title mat-card-title">Sales Pitch for Coupon Adverts</span>
            </div>
            <div className="card-image" >
              <img src="https://deliva-assets.s3.amazonaws.com/97bb6d59c8e93d81606f5f942c6c4b3f" alt="Sales Pitch for Coupon Adverts" />
            </div>
            <div className="card-content">
              <p> Training document for selling ads on DELIVA platform </p>
            </div>
            <div className="card-actions">
              <CustomButton >View &amp; Download</CustomButton>
              <CustomButton >Edit</CustomButton>
              <CustomButton className="deliva-btn-bordered">Delete</CustomButton>
            </div>
          </div>
          <div className="card">
            <div className="card-header-text">
              <span className="resource-title mat-card-title">Sales Pitch for Coupon Adverts</span>
            </div>
            <div className="card-image" >
              <img src="https://deliva-assets.s3.amazonaws.com/97bb6d59c8e93d81606f5f942c6c4b3f" alt="Sales Pitch for Coupon Adverts" />
            </div>
            <div className="card-content">
              <p> Training document for selling ads on DELIVA platform </p>
            </div>
            <div className="card-actions">
              <CustomButton >View &amp; Download</CustomButton>
              <CustomButton >Edit</CustomButton>
              <CustomButton className="deliva-btn-bordered">Delete</CustomButton>
            </div>
          </div>
          <div className="card">
            <div className="card-header-text">
              <span className="resource-title mat-card-title">Sales Pitch for Coupon Adverts</span>
            </div>
            <div className="card-image" >
              <img src="https://deliva-assets.s3.amazonaws.com/97bb6d59c8e93d81606f5f942c6c4b3f" alt="Sales Pitch for Coupon Adverts" />
            </div>
            <div className="card-content">
              <p> Training document for selling ads on DELIVA platform </p>
            </div>
            <div className="card-actions">
              <CustomButton >View &amp; Download</CustomButton>
              <CustomButton >Edit</CustomButton>
              <CustomButton className="deliva-btn-bordered">Delete</CustomButton>
            </div>
          </div>
          <div className="card">
            <div className="card-header-text">
              <span className="resource-title mat-card-title">Sales Pitch for Coupon Adverts</span>
            </div>
            <div className="card-image" >
              <img src="https://deliva-assets.s3.amazonaws.com/97bb6d59c8e93d81606f5f942c6c4b3f" alt="Sales Pitch for Coupon Adverts" />
            </div>
            <div className="card-content">
              <p> Training document for selling ads on DELIVA platform </p>
            </div>
            <div className="card-actions">
              <CustomButton >View &amp; Download</CustomButton>
              <CustomButton >Edit</CustomButton>
              <CustomButton className="deliva-btn-bordered">Delete</CustomButton>
            </div>
          </div>
          <div className="card">
            <div className="card-header-text">
              <span className="resource-title mat-card-title">Sales Pitch for Coupon Adverts</span>
            </div>
            <div className="card-image" >
              <img src="https://deliva-assets.s3.amazonaws.com/97bb6d59c8e93d81606f5f942c6c4b3f" alt="Sales Pitch for Coupon Adverts" />
            </div>
            <div className="card-content">
              <p> Training document for selling ads on DELIVA platform </p>
            </div>
            <div className="card-actions">
              <CustomButton >View &amp; Download</CustomButton>
              <CustomButton >Edit</CustomButton>
              <CustomButton className="deliva-btn-bordered">Delete</CustomButton>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
export default Resources;