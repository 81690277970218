import React, { useContext, useEffect, useState } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';

import DrawerContext from '../../../context/DrawerContext';
import AuthContext from '../../../context/AuthContext';
import CustomButton from '../../../common/CustomButton';

function AddCategory({ drawerDetails }) {

  console.log(drawerDetails.value);
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);

  const [categoryName, setCategoryName] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [categories, setCategories] = useState([]);
  const [mainCategory, setMainCategory] = React.useState('');

  const getAllCategories = () => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URI}/v2/categories`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status == 'success') {
          setLoading(false)
          setCategories(data?.data);
        }
      }).catch(err => {
        console.error(err)
      });

  }  

  const handleChange = (event) => {
    setMainCategory(event.target.value);
  };

  useEffect(() => {
    getAllCategories();
    if (drawerDetails.type.toLowerCase() == "edit") {

      setCategoryName(drawerDetails?.value.title);
      setFile(drawerDetails?.value.icon);

      if (drawerDetails.priority == "sub") {

        setMainCategory(drawerDetails?.value.categoryId);

      }

    }
  }, [drawerDetails]);

  const saveCategory = () => {

    setIsSaving(true);

    try {
      fetch(`${process.env.REACT_APP_API_URI}/v3/admin/category/${drawerDetails?.value.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
        body: JSON.stringify({
          isMainCat: !drawerDetails?.value.categoryId ? true : false,
          categoryTitle: categoryName,
          mainCategorySelected: mainCategory,
        }),
      })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          drawerCtx.setPageUpdate({page: "categories"});
          drawerCtx.closeDrawer();
          setIsSaving(false);
        }
      });
    } catch (error) {
      console.log("error : ", error);
    }

  }

  return (
    <div className="container">
      <div className="drawer">

        <header>
          <h1 style={{}} data-aos="fade-right" data-aos-delay="500">UPDATE "{categoryName.toUpperCase()}"" CATEGORY</h1>
        </header>

        <section className='add-category-content' >

          <h3>Update your <strong>Coupon Category</strong></h3><br/>

          { 
            !drawerDetails?.value.categoryId ? <>
              <TextField id="outlined-basic" fullWidth label={"Main Category Name"} value={categoryName} onChange={(e) => setCategoryName(e.target.value)} />
            </> : <>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Main Category</InputLabel>
                <Select labelId="demo-simple-select-label" id="demo-simple-select" value={mainCategory} label="Select Main Category" onChange={handleChange}>
                  {categories?.map((category, index) => {
                    return <MenuItem key={index} value={category.id}>{category.title}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <br/><br/>
              <TextField id="outlined-basic" fullWidth label={drawerDetails.type == "create" ? "Sub Category Name" : "Main Category Name"} value={categoryName} onChange={(e) => setCategoryName(e.target.value)} />
            </>
          }

        </section>

        <footer>
          <CustomButton onClick={() => drawerCtx.closeDrawer()} className="cancel">Cancel</CustomButton>
          <CustomButton onClick={() => saveCategory()} variant="contained" disabled={categoryName.length == 0}>
            {isSaving ? <CircularProgress style={{ color: "#fff" }} size={20} /> : "Save Changes" }
          </CustomButton>
        </footer>

      </div>
    </div>
  );

}
export default AddCategory;

