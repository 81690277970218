import React, { useContext, useEffect, useState } from 'react'

import { useTheme } from '@emotion/react';
import { FileOpenOutlined, FirstPage, FmdGoodTwoTone, KeyboardArrowLeft, KeyboardArrowRight, LastPage, LocationOn, MoreVertRounded, SearchOffOutlined, SearchOutlined } from '@mui/icons-material';
import { Avatar, Button, Checkbox, CircularProgress, IconButton, Input, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AuthContext from '../../context/AuthContext';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import EditIcon from '@mui/icons-material/Edit';

import MapIcon from "../../assets/img/company/location_icon.png";
import CouponIcon from "../../assets/img/company/coupons_icon.png";
import ModalContext from '../../context/ModalContext';
import DrawerContext from '../../context/DrawerContext';
import DescriptionIcon from '@mui/icons-material/Description';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { deepOrange } from '@mui/material/colors';

//components
import CustomButton from '../../common/CustomButton'
import TextInput from '../../common/TextInput';


export default function Categories() {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [companiesData, setCompaniesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [displayedData, setDisplayedData] = useState(categories);

  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);

  const getAllCategories = () => {    
    
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URI}/v2/categories`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
    .then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status == 'success') {
        setCategories(data?.data);
        setLoading(false)
      }
    }).catch(err => {
      console.error(err)
    });

  }

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    if (drawerCtx.pageUpdate?.page === "categories") {
      console.log("\n\nPage update requested. Refetching all categories...");
      drawerCtx.setPageUpdate({ page: "" });
      getAllCategories();
    } else {
      console.log("No page update requests detected...");
    }
  }, [drawerCtx.pageUpdate]);


  const handleModal = (modal, data) => {
    handleClose();
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };


  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [filterValue, setFilterValue] = useState('');
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {

    const filteredData = categories.filter((data) =>
    (
      data.title.toLowerCase().includes(searchInput.toLowerCase())
    )

    );


    setDisplayedData(filteredData);

  }, [filterValue, searchInput, loading])




  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <>
      <div className="categories-container">
        <div className='header-section'>
          <span className='title'>CATEGORIES</span>
          <div className='header-items-div'>
            <TextInput onChange={(e) => setSearchInput(e.target.value)} placeholder='Search' value={searchInput} />
            <CustomButton onClick={() => { toggleDrawerHandler('add-category', { type: "create", priority: 'sub' }) }}>Add Category</CustomButton>
          </div>
        </div>
        <Paper sx={{ width: '100%', height: 'calc(100% - 95px)', overflow: 'hidden', overflowY: "scroll" }}>
          {
            loading ? <div className="loading"><CircularProgress size={52} style={{color: "#e84118"}} /></div> : <>
              { 
                displayedData?.map((category, index) => <Accordion key={category?.id}>
                  <AccordionSummary expanded={expanded} expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" className='accordion-summary' TransitionComponent={Fade} TransitionProps={{ timeout: 200 }} sx={{'& .MuiAccordion-region': { height: expanded ? 'auto' : 0 }, '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },}}>
                    {/* <Avatar sx={{ bgcolor: "gray" }}>{category?.title[0]}</Avatar>  */}
                    <EditIcon onClick={() => toggleDrawerHandler("edit-category", { type: "edit", value: category, priority: "main" })} />&nbsp;&nbsp;
                    <span className='category-title'>{category?.title}</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    { category.subcategories?.map((subCategory, index) => 
                      <Accordion onClick={() => toggleDrawerHandler("edit-category", { type: "edit", value: { ...subCategory, mainCategory: category.title , categoryId: category.id }, priority: "sub" })} style={{ fontSize: "medium" }} expanded={false}>
                        <AccordionSummary aria-expanded="false" aria-controls="panel1-content-subcategory" id="panel1-header-items">
                          <EditIcon onClick={(e) => { e.stopPropagation(); toggleDrawerHandler("edit-category", { type: "edit", value: { ...subCategory, mainCategory: category.title , categoryId: category.id }, priority: "sub" }); }} style={{ fontSize: "medium" }} />
                          <span className='subCategory-title'>{subCategory?.title}</span>
                        </AccordionSummary>
                      </Accordion>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          }
        </Paper>
      </div>
    </>
  );
}
