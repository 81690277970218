import { createContext, useState } from 'react';

const NavbarContext = createContext({
  open: true,
  openNavbar: (openNavbar) => {},
  closeNavbar: (closeNavbar) => {}
});

export function NavbarContextProvider(props) {

  const [open, setOpen] = useState(true);

  function openNavbarHandler() {
    setOpen(true);
  }

  function closeNavbarHandler() {
    setOpen(false);
  }


  const context = {
    open: open,
    openNavbar: openNavbarHandler,
    closeNavbar: closeNavbarHandler
  };

  return <NavbarContext.Provider value={context}>
    {props.children}
  </NavbarContext.Provider>
}

export default NavbarContext;