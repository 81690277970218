import React, { useContext, useEffect, useState } from 'react'

import { useTheme } from '@emotion/react';
import { FileOpenOutlined, FirstPage, FmdGoodTwoTone, KeyboardArrowLeft, KeyboardArrowRight, LastPage, LocationOn, MoreVertRounded, SearchOffOutlined, SearchOutlined } from '@mui/icons-material';
import { Button, Checkbox, CircularProgress, IconButton, Input, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import AuthContext from '../../context/AuthContext';

import MapIcon from "../../assets/img/company/location_icon.png";
import CouponIcon from "../../assets/img/company/coupons_icon.png";
import ModalContext from '../../context/ModalContext';
import DrawerContext from '../../context/DrawerContext';
import DescriptionIcon from '@mui/icons-material/Description';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import CustomButton from '../../common/CustomButton';
import TextInput from '../../common/TextInput';

function TablePaginationActions(props) {
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0);
  const [tableData, setTableData] = useState([])
  const [selected, setSelected] = useState([])
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };


  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

export default function CustomPaginationActionsTable() {

  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedUser, setSelectedUser] = useState(null);
  const [usersData, setusersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayedData, setDisplayedData] = useState(usersData);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const [searchInput, setSearchInput] = useState('');

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - displayedData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatus = (status) => {
    // switch (status) {
    //   case 'active':
    //     return <div className='status-active'>Active</div>
    //     break;

    //   case 'invite_sent':
    //     return <div className='status-invite'>Invite Sent</div>
    //     break;

    //   default:
    //     break;
    // }
    if (status == "pending") {
      return <div class='status-pending'>Pending</div>;
    } else if (status == "invite_sent") {
      return <div className='status-invite'>Invite Sent</div>;
    } else if (status == "active") {
      return <div className='status-active'>Active</div>;
    } else if (status == "suspended") {
      return <div class='status-suspended'>Suspended</div>;
    }
  }



  const getAllUsers = () => {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URI}/v3/users`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
    .then(response => {
      return response.json();
    }).then(data => {
      if (data.status == 'success') {
        setLoading(false)
        console.log(data?.data, "this is the data");
        setusersData(data?.data);

      }
    }).catch(err => {
      console.error(err)
      // setUpdatingStatus(false)
    });

  }


  useEffect(() => {
    getAllUsers();
  }, [])


  const handleModal = (modal, data) => {
    handleClose();
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const filteredData = usersData?.filter((data) => (
      data?.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
      (data?.keywords?.toLowerCase().includes(searchInput.toLowerCase())))
    );
    setDisplayedData(filteredData);
  }, [filterValue, searchInput, loading])

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  }
  
  const open2 = Boolean(anchorElFilter);
  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilter = (value) => {
    setAnchorElFilter(null);
    setFilterValue(value)
  };

  return (
    <>
      <div className="agents-container">
        <div className='header-section'>
          <span className='title'>USERS</span>
          <div className='header-items-div'>
            <TextInput value={searchInput} onChange={(e) => { setSearchInput(e.target.value); console.log(e.target.value) }} placeholder='Search' />
            <div>
              <CustomButton onClick={handleClickFilter} className={'deliva-btn-bordered'} >
                Filter By :- {filterValue !== '' ? filterValue : 'All'}
              </CustomButton>
              <Menu id="basic-menu" anchorEl={anchorElFilter} open={open2} onClose={() => handleCloseFilter('')}>
                <MenuItem onClick={() => handleCloseFilter('user')}>User</MenuItem>
                {/* <MenuItem onClick={()=>handleCloseFilter('rescue')}>Rescue</MenuItem>
                <MenuItem onClick={()=>handleCloseFilter('police')}>Police</MenuItem>
                <MenuItem onClick={()=>handleCloseFilter('shelters')}>Shelters</MenuItem>
                <MenuItem onClick={()=>handleCloseFilter('fire')}>Fire</MenuItem>
                <MenuItem onClick={()=>handleCloseFilter('pests')}>Pests</MenuItem>
                 */}
                <MenuItem onClick={() => handleCloseFilter('')}>All</MenuItem>
              </Menu>
            </div>
            <CustomButton /* onClick={() => { toggleDrawerHandler('add-user', null) }} */>+ Add User</CustomButton>
          </div>
        </div>
        <TableContainer component={Paper} className='agent-table'>
          <Table sx={{ minWidth: 500 }}>
          <TableHead className='agent-table-head'>
            <TableRow>
              <TableCell align="left" className="agent-head-cell">Name</TableCell>
              <TableCell align="left" className="agent-head-cell">Email</TableCell>
              <TableCell align="left" className="agent-head-cell">Phone</TableCell>             
              <TableCell align="left" className="agent-head-cell">Location</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
            <TableBody>
            {loading ?  <TableRow>
              <TableCell colSpan={6} align="center">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                  <CircularProgress />
                </div>
              </TableCell>
            </TableRow>: (rowsPerPage > 0
                ? displayedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : displayedData
              ).map((data, index) => (
                <TableRow key={data.id}>
                  <TableCell style={{ width: 150 }} align="left">
                    <span>{data.name}</span>
                  </TableCell>
                  <TableCell style={{ width: 150 }} align="left">
                    {data.email}
                  </TableCell>
                  <TableCell style={{ width:150 }} align="left">
                    {data.phone}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="left">
                    {data.country}
                  </TableCell>
                  <TableCell style={{ width: 10 }} align="left">
                  <MoreVertRounded aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true" null
                        aria-expanded={open ? 'true' : undefined} onClick={(e) => {
                          handleClick(e, data);
                        }} />                 
                </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} className='agent-table-pagination'>
          <Table sx={{ minWidth: 500 }}>
            <TableBody>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={displayedData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div> 
    </>
  );
}
