import { createContext, useContext, useEffect, useState } from "react";


const CouponContext = createContext();

export const useCouponContext = () => useContext(CouponContext);

export const CouponProvider = ({ children }) => {

  const initialState = {
    newCoupon: true,
    currentStep: 0,
    selectedCompany: null,
    couponDealOption: {
      first: { dealVal1: "", dealVal2: "", },
      second: { dealVal1: "", },
      third: { dealVal1: "", dealVal2: "", },
      couponExpiration: false, //  True if coupon doesn't expire, False if Expire
      expirationButton: 1,
      expirationOption: 1,
      description: "",
      selectedDealOptions: 0,
    },
    couponDetailsPage: {
      categories: [],
      couponClaimUrl: "",
      phoneNumber: "",
      emailAddress: "",
      countries: [],
      keywords: [],
      currentKeyword: "",
      couponCode: "",
    },
    currentTermsCondList: [{ id: 1, instruction: 'Valid to new and existing customers.', checked: false }, { id: 2, instruction: 'Valid to new customers only.', checked: false }, { id: 3, instruction: 'NOT applicable to already discounted products and services.', checked: false },],
    termCond: '',
    currinst: '',
    currentInstruction: [{ id: 1, instruction: 'Simply navigate to their website or online store.', checked: false }, { id: 2, instruction: 'You must register online for this discount.', checked: false }, { id: 3, instruction: 'Install the vendor’s mobile app.', checked: false }, { id: 4, instruction: 'Enter this code on the online checkout page before payment.', checked: false }, { id: 5, instruction: 'Show this code to the teller in-store.', checked: false }, { id: 6, instruction: 'Present to salesperson after receiving a quote.', checked: false }, { id: 7, instruction: 'Call for an appointment.', checked: false }, { id: 8, instruction: 'Email for an appointment.', checked: false }, { id: 9, instruction: 'WhatsApp for appointment.', checked: false },],
    locationDetailsPage: {
      locations: [],
      checkedId: [],
      onlineOnly: "no",
      callToActionButton: [{ id: "navigateBtn", name: 'Navigate Button', checked: false }, { id: "websiteBtn", name: 'Webiste Button', checked: false }, { id: "callBtn", name: 'Call Button', checked: false }, { id: "emailBtn", name: 'Email Button', checked: false },]
    },
    validFor: '1 days'
  };

  const [couponState, setCouponState] = useState(JSON.parse(localStorage.getItem("coupon-state")) || initialState);


  useEffect(() => {
    localStorage.setItem("coupon-state", JSON.stringify(couponState));
  }, [couponState]);


  const updateCouponState = (state) => {

    setCouponState((prevState) => ({
      ...prevState,
      ...state,
    }));
  }

  const clearState = () => {
    setCouponState(initialState);
  }

  const editCouponState = (newCouponData) => {
    console.log(newCouponData, "this is what we are passing here");

    var recievedCoupon = newCouponData.coupon;

    var editInitialState = {
      newCoupon: false,
      couponId: recievedCoupon.id,
      currentStep: 1,
      selectedCompany: newCouponData.selectedCompany,
      couponDealOption: {
        first: {
          dealVal1: "",
          dealVal2: "",
        },
        second: {
          dealVal1: "",

        },
        third: {
          dealVal1: "",
          dealVal2: "",

        },
        couponExpiration: recievedCoupon.validFor != 99999, //  True if coupon doesn't expire, False if Expire
        expirationButton: 1,
        expirationOption: 1,
        description: recievedCoupon.description,
        selectedDealOptions: 0,
      },
      couponDetailsPage: {
        categories: recievedCoupon.categories || [],
        couponClaimUrl: recievedCoupon.website || "",
        phoneNumber: recievedCoupon.mobile || "",
        emailAddress: recievedCoupon.email || "",
        countries: [],
        keywords: recievedCoupon.keywords?.split("|") || [],
        currentKeyword: "",
        couponCode: recievedCoupon.coupon,
      },
      currentTermsCondList: [
        { id: 1, instruction: 'Valid to new and existing customers.', checked: false },
        { id: 2, instruction: 'Valid to new customers only.', checked: false },
        { id: 3, instruction: 'NOT applicable to already discounted products and services.', checked: false },


      ],
      termCond: '',
      currinst: '',
      currentInstruction: [
        { id: 1, instruction: 'Simply navigate to their website or online store.', checked: false },
        { id: 2, instruction: 'You must register online for this discount.', checked: false },
        { id: 3, instruction: 'Install the vendor’s mobile app.', checked: false },
        { id: 4, instruction: 'Enter this code on the online checkout page before payment.', checked: false },
        { id: 5, instruction: 'Show this code to the teller in-store.', checked: false },
        { id: 6, instruction: 'Present to salesperson after receiving a quote.', checked: false },
        { id: 7, instruction: 'Call for an appointment.', checked: false },
        { id: 8, instruction: 'Email for an appointment.', checked: false },
        { id: 9, instruction: 'WhatsApp for appointment.', checked: false },

      ],
      locationDetailsPage: {
        locations: [],
        checkedId: [],
        onlineOnly: recievedCoupon.onlineOnly,
        callToActionButton: [
          { id: "navigateBtn", name: 'Navigate Button', checked: false },
          { id: "websiteBtn", name: 'Webiste Button', checked: false },
          { id: "callBtn", name: 'Call Button', checked: false },
          { id: "emailBtn", name: 'Email Button', checked: false },
        ]
      },
      validFor: `${couponState.couponDealOption.expirationOption} ${getExpirationOption(couponState.couponDealOption.expirationButton)}`
    };


    editInitialState.locationDetailsPage.callToActionButton.forEach(button => {

      switch (button.id) {
        case "navigateBtn":
          button.checked = recievedCoupon.navigateBtn && recievedCoupon.navigateBtn === "yes";
          break;
        case "websiteBtn":
          button.checked = recievedCoupon.websiteBtn && recievedCoupon.websiteBtn === "yes";
          break;
        case "callBtn":
          button.checked = recievedCoupon.callBtn && recievedCoupon.callBtn === "yes";
          break;
        case "emailBtn":
          button.checked = recievedCoupon.emailBtn && recievedCoupon.emailBtn === "yes";
          break;
        default:
          break;
      }
    })

    recievedCoupon.instructions?.split("|").forEach((instruction) => {

      const existingIndex = editInitialState.currentInstruction.findIndex((item) => item.instruction == instruction);

      if (existingIndex !== -1) {
        editInitialState.currentInstruction[existingIndex].checked = true;
      } else {
        editInitialState.currentInstruction.push({
          id: editInitialState.currentInstruction.length + 1,
          instruction: instruction,
          checked: true
        });
      }
    });

    recievedCoupon.terms?.split("|").forEach((terms) => {

      const existingIndex = editInitialState.currentTermsCondList.findIndex((item) => item.instruction == terms);

      if (existingIndex !== -1) {
        editInitialState.currentTermsCondList[existingIndex].checked = true;
      } else {
        editInitialState.currentTermsCondList.push({
          id: editInitialState.currentTermsCondList.length + 1,
          instruction: terms,
          checked: true
        });
      }
    });



    const getCurrentDealOption = (dealValue, dealType) => {

      switch (dealType) {

        case "PER":
          editInitialState.couponDealOption.selectedDealOptions = 1;
          editInitialState.couponDealOption.second.dealVal1 = dealValue;
          break;
        default:
          break;
      }

    }


    getCurrentDealOption(recievedCoupon.deal, recievedCoupon.dealUnit);
    setCouponState(editInitialState);
  }



  const getExpirationOption = (selectedButton) => {

    switch (selectedButton) {
      case 1:
        return "Weeks";
      case 2:
        return "Months";
      case 3:
        return "Days";
      case 4:
        return "Years";
    }

  }

  const getFinalCoupounState = () => {
    var finalCouponState = {
      "companyId": couponState.companyId.id,
      "coupon": couponState.couponDetailsPage.couponCode,
      "contactId": "1",
      "description": couponState.couponDetailsPage.description,
      "validFor": "2 Weeks",
      "locations": [
        {
          "locationId": "123",
          "title": "Test Location",
          "address": "Noida sector 8",
          "country": "India",
          "latitude": "12.33",
          "longitude": "12.33"
        },
        {
          "locationId": "124",
          "title": "Test Location 2",
          "address": "Noida sector 8",
          "country": "India",
          "latitude": "12.33",
          "longitude": "12.33"
        }
      ],
      "categories": [
        112,
        113,
        114
      ],
      "website": couponState.couponDetailsPage.couponClaimUrl,
      "mobile": couponState.couponDetailsPage.phoneNumber,
      "email": couponState.couponDetailsPage.emailAddress,
      "onlineOnly": "yes",
      "countries": "2",
      "deal": "35",
      "dealUnit": "INR",
      "termsAndConditions": ["This is some terms and conditions text", "This is some terms and conditions text 2", "This is some terms and conditions text 3"],
      "userScope": "agen"
    };

    finalCouponState.contactId = ""; // Not Sure about this one

    if (couponState.couponDealOption.couponExpiration) {
      finalCouponState.validFor = "No Exipry"; // Expiration
    } else {
      finalCouponState.validFor = `${couponState.couponDealOption.expirationOption} ${getExpirationOption(couponState.couponDealOption.expirationButton)}`; // Expiration
    }

    finalCouponState.locations = []; // need to work on this
    finalCouponState.categories = []; // need to store the coupon id



    return finalCouponState;
  }

  return <CouponContext.Provider value={{ couponState, updateCouponState, clearState, editCouponState, getFinalCoupounState }}>{children}</CouponContext.Provider>

}