import React from 'react'
import { Card, Step, StepLabel, Stepper } from '@mui/material'
import QontoStepIcon, { QontoConnector } from '../QuontoStepIcon/QontoStepIcon'

export default function HeaderStepper({ steps, currentStep, selectedCompany, resetCoupon }) {
  return (
    <div className='header-stepper-section-v2'>
      <span onClick={() => resetCoupon()} style={{ color: 'blue', cursor: "pointer" }}>Reset and start a new coupon</span>
      <Card sx={{ backgroundColor: 'white', width: '100%', padding: '.5rem 1rem', display: 'flex', alignItems: 'center' }}>
        <img src={selectedCompany.logo} className='logo-image'></img>
        <Stepper sx={{ width: '100%' }} alternativeLabel activeStep={currentStep - 1} connector={<QontoConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Card>
    </div>
  )
}
