import React, { useContext, useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { GoogleMap, LoadScriptNext, MarkerF } from '@react-google-maps/api'; // Use LoadScriptNext
import AuthContext from '../../../context/AuthContext';
import DrawerContext from '../../../context/DrawerContext';
import TextInput from '../../../common/TextInput';

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const DefaultLocation = {
  lat: 37.7749, // Default to San Francisco
  lng: -122.4194,
};

const DefaultZoom = 10;

const AddAgent = () => {
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(DefaultLocation);
  const [agentObj, setAgentObj] = useState({ first_name: "", last_name: "", email: "", phone: "", status: "", location: "" });
  const [originalData, setOriginalData] = useState(null);
  const isEditMode = drawerCtx.details && drawerCtx.details.id;

  useEffect(() => {
    if (isEditMode) {
      const { firstName, lastName, email, phone, status, location } = drawerCtx.details;
      const originalObj = { first_name: firstName, last_name: lastName, email, phone, status, location };
      setAgentObj(originalObj);
      setOriginalData(originalObj);

      // if (location) {
      //   const [lat, lng] = location.split(',').map(Number);
      //   setSelectedLocation({ lat, lng });
      //   fetchAddress(lat, lng);
      // }
    }
  }, [drawerCtx.details, isEditMode]);

  // Fetch address using Nominatim reverse geocoding API
  const fetchAddress = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=18&addressdetails=1`
      );
      const data = await response.json();
      if (data && data.address) {
        const { road, city, state, postcode, country } = data.address;
        const formattedAddress = `${road || ''}, ${city || ''}, ${state || ''}, ${postcode || ''}, ${country || ''}`;
        setAddress(formattedAddress);
        setAgentObj((prev) => ({ ...prev, location: formattedAddress }));
      } else {
        setAddress('Address not found');
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      setAddress('Error fetching address');
    }
  };

  const handleMapClick = async(e) => {
    const { latLng } = e;
    const lat = latLng.lat();
    const lng = latLng.lng();

    await setSelectedLocation({ lat, lng });
    console.log(selectedLocation)
    fetchAddress(lat, lng);
    setAgentObj({ ...agentObj, location: `${lat}, ${lng}` });
  };

  const hasChanges = () => {
    return JSON.stringify(agentObj) !== JSON.stringify(originalData);
  };

  const submitAgent = async () => {
    if (isEditMode && !hasChanges()) {
      return;
    }

    setLoading(true);
    const { first_name, last_name, email, phone, status } = agentObj;
    const url = isEditMode
      ? `${process.env.REACT_APP_API_URI}/v3/admin/agent/${drawerCtx.details.id}` //Edit
      : `${process.env.REACT_APP_API_URI}/v3/admin/agent`; //Add

    const method = isEditMode ? 'PUT' : 'POST';

    try {
      const response = await fetch(url, {
        method: method,
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ firstName: first_name, lastName: last_name, email, phone, status, location: address })
      });
      const data = await response.json();
      if (data.status === "success") {
        setLoading(false);
        drawerCtx.closeDrawer("add-agent");
        drawerCtx.closeDrawer("edit-agent");
      }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };

  return (
    <div className="drawer-container">
      <div className="drawer">
        <header>
          <h1>{isEditMode ? "Edit Agent" : "Add Agent"}</h1>
        </header>
        <section style={{ padding: '1rem' }}>
          <TextInput name="first_name" label="First Name" value={agentObj.first_name} onChange={(e) => setAgentObj({ ...agentObj, first_name: e.target.value })} placeholder="First Name" />
          <TextInput name="last_name" label="Last Name" value={agentObj.last_name} onChange={(e) => setAgentObj({ ...agentObj, last_name: e.target.value })} placeholder="Last Name" />
          <TextInput name="email" label="Email" value={agentObj.email} onChange={(e) => setAgentObj({ ...agentObj, email: e.target.value })} placeholder="Email" />
          <TextInput name="phone" label="Phone" value={agentObj.phone} onChange={(e) => setAgentObj({ ...agentObj, phone: e.target.value })} placeholder="Phone" />
          <TextInput name="status" label="Status" value={agentObj.status} onChange={(e) => setAgentObj({ ...agentObj, status: e.target.value })} placeholder="Status" />

          <div style={{ margin: "1rem 0" }}>
            <h4>Select Location</h4>
            <LoadScriptNext googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={selectedLocation}
                zoom={DefaultZoom}
                onClick={handleMapClick}
              >
                <MarkerF position={selectedLocation}/>
              </GoogleMap>
            </LoadScriptNext>
          </div>
          <TextInput name="address" label="Address" value={agentObj.location} onChange={(e) => { setAddress(e.target.value); setAgentObj({ ...agentObj, location: e.target.value }); }} placeholder="Address" />

          <div className="double-column-form aligned-right margin-top">
            <button className='btn-primary' onClick={submitAgent}>
              {loading ? <CircularProgress size={22} style={{ color: "#fff" }} /> : isEditMode ? "Update" : "Add"}
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AddAgent;
