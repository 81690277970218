import { Card, CircularProgress, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react'

export default function ConfirmCoupon({ selectedCompany, couponDealOption, couponDetails, orderedCategoriesData, termsConditions, instructions, locationDetails }) {

  const [selectedLocations, selectLocations] = useState([]);

  useEffect(() => {
    selectLocations(locationDetails?.locations.filter(lc => locationDetails?.checkedId?.includes(lc.id)))
  }, [locationDetails])

  const getExpirationOption = (selectedButton) => {

    switch (selectedButton) {
      case 1:
        return "Weeks";
      case 2:
        return "Months";
      case 3:
        return "Days";
      case 4:
        return "Years";
    }

  }

  const getSelectedDealOption = () => {

    if (couponDealOption.selectedDealOptions == 0) {
      return <div>
        <div className='big-detail-title white-color'>{couponDealOption.first.dealVal1}</div>
        <div className='detail-title white-color'>{couponDealOption.first.dealVal2}</div>
        <div className='detail-title white-color'>FREE</div>
      </div>
    } else if (couponDealOption.selectedDealOptions == 1) {
      return <div>
        <div className='big-detail-title white-color'>{couponDealOption.second.dealVal1}</div>
        <div className='detail-title white-color'>% OFF</div>
      </div>
    } else {
      return <div> <div className='big-detail-title white-color'>{couponDealOption.third.dealVal1}</div>
        <div className='detail-title white-color'>{couponDealOption.third.dealVal2}</div>
      </div>
    }
  }
  return (
    <div className='create-coupon-section-v2'>
      <Card className='card-container' sx={{ width: '100%' }}>
        <span className='title'>Deal</span>
        <div className='three-col-container'>
          <div className='single-item right-border-item'>
            <span className='big-title'>{selectedCompany.companyDetails.company}</span>
            <span className='detail-title'>{couponDealOption?.couponExpiration ? 'Coupon does not expire' : `Offer is valid for ${couponDealOption.expirationOption} ${getExpirationOption(couponDealOption.expirationButton)}`}</span>
          </div>
          <div className='single-item right-border-item'>
            <Card className='card center-item logo-item'>
              {getSelectedDealOption()}
              <img src={selectedCompany.logo} className='logo-image'></img>
            </Card>
          </div>

          <div className='single-item'>
            <span className='final-title'>Coupon Description</span>
            <span className='detail-title'>{couponDealOption.description}</span>
          </div>
        </div>
      </Card>
      <Card className='card-container' sx={{ width: '100%' }}>
        <span className='title'>Coupon Details</span>
        <div className='three-col-container'>

          <div className='single-item right-container right-border-item'>
            <span className='final-title'>Website (Online Coupon URL)</span>
            {couponDetails.couponClaimUrl.split("\n").map((item, index) => <span className='detail-title'>{item}</span>)}
            <span className='final-title'>Contact Details</span>
            {couponDetails.emailAddress.split("\n").map((item, index) => <span className='detail-title' key={index}>{item}</span>)}
          </div>

          <div className='single-item right-border-item'>
            <span className='final-title'>Coupon Code</span>
            <span className='big-detail-title'>{couponDetails.couponCode}</span>
          </div>

          <div className='single-item'>
            <span className='final-title'>Categories</span>
            <div className='item-center-container'>
              {orderedCategoriesData?.filter((item) => couponDetails.categories.includes(item.id)).map((item, index) => <div key={index} className='selected-item-card'>{item.title}</div>)}
            </div>
          </div>
        </div>



      </Card>

      <div className='horizontal-row-card-container'>
        <Card className='card-container' sx={{ width: '50%' }}>
          <span className='title'>Terms & Conditions</span>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {termsConditions.map((value, index) => {
              const labelId = `checkbox-list-label-${value.id}`;
              if (value.checked) {
                return (
                  <ListItem
                    key={value.id}
                    secondaryAction={
                      <IconButton edge="end" aria-label="comments">
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemText id={labelId} primary={`${value.instruction}`} />
                  </ListItem>
                );
              }
            })}
          </List>
        </Card>
        <Card className='card-container' sx={{ width: '50%' }}>
          <span className='title'>Locations</span>

          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {selectedLocations.map((value, index) => {
              const labelId = `checkbox-list-label-${value.id}`;
              return (
                <ListItem
                  key={value.id}
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemText id={labelId} primary={`${value.address}`} />
                </ListItem>
              );
            })}
          </List>
        </Card>
      </div>
      <Card className='card-container' sx={{ width: '100%' }}>
        <span className='title'>Instructions</span>

        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {instructions.map((value, index) => {
            const labelId = `checkbox-list-label-${value.id}`;

            if (value.checked) {
              return (
                <ListItem
                  key={value.id}
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemText id={labelId} primary={`${value.instruction}`} />
                </ListItem>
              );
            }
          })}
        </List>
      </Card>
    </div>
  )
}
