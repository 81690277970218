import { Add } from '@mui/icons-material';
import { Button, Card, Checkbox, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material';
import React, { useState } from 'react'

export default function TermsInstructions({ termsConditions, instructions, addTermsConditions, addInstructions, toggleTermCondition, toggleInstruction, errors, setError }) {

  const [termsField, setTermsField] = useState('');
  const [instructionsField, setInstructionsField] = useState('');

  const handleAdding = (_type) => {
    if (_type == 'terms') {
      if (termsField && termsField !== '') {
        addTermsConditions({ id: termsConditions.length + 1, instruction: termsField, checked: true });
        setTermsField('');
      }
    } else if (_type == 'instructions') {
      if (instructionsField && instructionsField !== '') {
        addInstructions({ id: instructions.length + 1, instruction: instructionsField, checked: true });
        setInstructionsField('');
      }
    }
  }

  const handleToggle = (_value, _type) => () => {
    if (_type == 'terms') {
      toggleTermCondition(_value.id);
      setError('terms', false);
    } else {
      toggleInstruction(_value.id);
      setError('instructions', false);
    }
  };

  return (
    <div className='create-coupon-section-v2'>
      <div className='horizontal-row-card-container'>

        <Card className='card-container' sx={{ width: '40%' }}>
          <span className='title'>Add a New Term or Condition</span>
          <img src='https://admin.delivait.com/assets/img/add-terms.png' />
          <span className='full-width'>Enter just 1 term or condition at a time</span>
          <TextField
            value={termsField}
            onChange={(e) => setTermsField(e.target.value)}
            id="standard-multiline-static"
            fullWidth
            multiline
            rows={3}
            placeholder='i.e Deliva reserves the right to discontinue a coupon at any time.'
            variant="standard"
          />
          <Button onClick={() => handleAdding('terms')}><Add /></Button>
        </Card>


        <Card className='card-container' sx={{ width: '60%' }}>
          <span className='title'>Current Terms & Conditions</span>

          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {termsConditions.map((value) => {
              const labelId = `checkbox-list-label-${value.id}`;

              return (
                <ListItem
                  key={value.id}
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={handleToggle(value, "terms")} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={value.checked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${value.instruction}`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          {errors?.terms?.show && <div className="coupon-error-text">{errors.terms.message}</div>}
        </Card>


      </div>
      <div className='horizontal-row-card-container'>

        <Card className='card-container' sx={{ width: '40%', justifyContent: 'center' }}>
          <span className='title'>Add instructions which indicate how a user can redeem this coupon</span>
          <img src='https://admin.delivait.com/assets/img/add-terms.png' />
          <span className='full-width'>Enter just 1 instruction at a time</span>
          <TextField
            id="standard-multiline-static"
            value={instructionsField}
            onChange={(e) => setInstructionsField(e.target.value)}
            fullWidth
            multiline
            rows={3}
            placeholder='Not valid with other coupons.'
            variant="standard"
          />

          <Button onClick={() => handleAdding('instructions')}><Add /></Button>

        </Card>

        <Card className='card-container' sx={{ width: '60%' }}>
          <span className='title'>Current Instructions</span>

          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {instructions.map((value) => {
              const labelId = `checkbox-list-label-${value.id}`;

              return (
                <ListItem
                  key={value.id}
                  secondaryAction={
                    <IconButton edge="end" aria-label="comments">
                    </IconButton>
                  }
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={handleToggle(value, "instructions")} dense>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={value.checked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={`${value.instruction}`} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>

          {errors?.instructions?.show && <div className="coupon-error-text">{errors.instructions.message}</div>}
        </Card>
      </div>
    </div>
  )
}
