import { createContext, useContext, useEffect, useState } from "react";


const CouponContextV2 = createContext();

export const useCouponContextV2 = () => useContext(CouponContextV2);

export const CouponProviderV2 = ({ children }) => {

  const initialState = {
    newCoupon: true,
    currentStep: 0,
    selectedCompany: null,
    couponDealOption: {
        first: { dealVal1: "", dealVal2: "", },
        second: { dealVal1: "", },
        third: { dealVal1: "", dealVal2: "", },
        couponExpiration: false, //  True if coupon doesn't expire, False if Expire
        expirationButton: 1,
        expirationOption: 1,
        description: "",
        selectedDealOptions: 0,
    },
    couponDetails: {
      categories: [],
      couponClaimUrl: "",
      phoneNumber: "",
      emailAddress: "",
      countries: [],
      keywords: [],
      couponCode: "",
    },
    termsConditions: [
      { id: 1, instruction: 'Valid to new and existing customers.', checked: false },
      { id: 2, instruction: 'Valid to new customers only.', checked: false },
      { id: 3, instruction: 'NOT applicable to already discounted products and services.', checked: false },
    ],
    instructions: [
      { id: 1, instruction: 'Simply navigate to their website or online store.', checked: false },
      { id: 2, instruction: 'You must register online for this discount.', checked: false },
      { id: 3, instruction: 'Install the vendor’s mobile app.', checked: false },
      { id: 4, instruction: 'Enter this code on the online checkout page before payment.', checked: false },
      { id: 5, instruction: 'Show this code to the teller in-store.', checked: false },
      { id: 6, instruction: 'Present to salesperson after receiving a quote.', checked: false },
      { id: 7, instruction: 'Call for an appointment.', checked: false },
      { id: 8, instruction: 'Email for an appointment.', checked: false },
      { id: 9, instruction: 'WhatsApp for appointment.', checked: false },
    ],
    locationDetails: {
      locations: [],
      checkedId: [],
      onlineOnly: "no",
      callToActionButton: [
        { id: "navigateBtn", name: 'Navigate Button', checked: false },
        { id: "websiteBtn", name: 'Webiste Button', checked: false },
        { id: "callBtn", name: 'Call Button', checked: false },
        { id: "emailBtn", name: 'Email Button', checked: false }],
    },
    validFor: '1 Weeks'
  };

  const [couponState, setCouponState] = useState(JSON.parse(localStorage.getItem("deliva-admin-active-coupon-details")) || initialState);


  useEffect(() => {
    localStorage.setItem("deliva-admin-active-coupon-details", JSON.stringify(couponState));
  }, [couponState]);


  const updateCouponState = (state) => {

    setCouponState((prevState) => ({
      ...prevState,
      ...state,
    }));
  }

  const clearState = () => {
    setCouponState(initialState);
  }

  const editCouponState = (companyDetails, couponDetails) => {
    console.log(couponDetails);
    const editState = {
      newCoupon: false,
      currentStep: 1,
      selectedCompany: companyDetails,
      couponDealOption: {
          first: { dealVal1: "", dealVal2: "", },
          second: { dealVal1: "", },
          third: { dealVal1: "", dealVal2: "", },
          couponExpiration: couponDetails.validFor === 99999, //  True if coupon doesn't expire, False if Expire
          expirationButton: 1,
          expirationOption: 1,
          description: couponDetails.description,
          selectedDealOptions: 0,
      },
      couponDetails: {
        categories: couponDetails.categories || [],
        couponClaimUrl: couponDetails.website || '',
        phoneNumber: couponDetails.mobile || '',
        emailAddress: couponDetails.email || '',
        countries: [],
        keywords: [],
        couponCode: companyDetails.coupon || '',
      },
      termsConditions: [
        { id: 1, instruction: 'Valid to new and existing customers.', checked: false },
        { id: 2, instruction: 'Valid to new customers only.', checked: false },
        { id: 3, instruction: 'NOT applicable to already discounted products and services.', checked: false },
      ],
      instructions: [
        { id: 1, instruction: 'Simply navigate to their website or online store.', checked: false },
        { id: 2, instruction: 'You must register online for this discount.', checked: false },
        { id: 3, instruction: 'Install the vendor’s mobile app.', checked: false },
        { id: 4, instruction: 'Enter this code on the online checkout page before payment.', checked: false },
        { id: 5, instruction: 'Show this code to the teller in-store.', checked: false },
        { id: 6, instruction: 'Present to salesperson after receiving a quote.', checked: false },
        { id: 7, instruction: 'Call for an appointment.', checked: false },
        { id: 8, instruction: 'Email for an appointment.', checked: false },
        { id: 9, instruction: 'WhatsApp for appointment.', checked: false },
      ],
      locationDetails: {
        locations: [],
        checkedId: [],
        onlineOnly: couponDetails.onlineOnly,
        callToActionButton: [
          { id: "navigateBtn", name: 'Navigate Button', checked: couponDetails.navigateBtn === "yes" },
          { id: "websiteBtn", name: 'Webiste Button', checked: couponDetails.websiteBtn === "yes" },
          { id: "callBtn", name: 'Call Button', checked: couponDetails.callBtn === "yes" },
          { id: "emailBtn", name: 'Email Button', checked: couponDetails.emailBtn === "yes" }],
      },
      validFor: '1 Weeks'
    };

    // Terms
    let includedTerms = couponDetails.terms.split(',');
    includedTerms = includedTerms.map(term => term.trim());
    editState.termsConditions.forEach(term => {
      term.checked = includedTerms.includes(term.instruction);
    })

    setCouponState(editState);
  }

  // const editCouponState = (newCouponData) => {
  //   console.log(newCouponData, "this is what we are passing here");

  //   var recievedCoupon = newCouponData.coupon;

  //   var editInitialState = {
  //     newCoupon: false,
  //     couponId: recievedCoupon.id,
  //     currentStep: 1,
  //     selectedCompany: newCouponData.selectedCompany,
  //     couponDealOption: {
  //       first: {
  //         dealVal1: "",
  //         dealVal2: "",
  //       },
  //       second: {
  //         dealVal1: "",

  //       },
  //       third: {
  //         dealVal1: "",
  //         dealVal2: "",

  //       },
  //       couponExpiration: recievedCoupon.validFor != 99999, //  True if coupon doesn't expire, False if Expire
  //       expirationButton: 1,
  //       expirationOption: 1,
  //       description: recievedCoupon.description,
  //       selectedDealOptions: 0,
  //     },
  //     couponDetailsPage: {
  //       categories: recievedCoupon.categories || [],
  //       couponClaimUrl: recievedCoupon.website || "",
  //       phoneNumber: recievedCoupon.mobile || "",
  //       emailAddress: recievedCoupon.email || "",
  //       countries: [],
  //       keywords: recievedCoupon.keywords?.split("|") || [],
  //       currentKeyword: "",
  //       couponCode: recievedCoupon.coupon,
  //     },
  //     currentTermsCondList: [
  //       { id: 1, instruction: 'Valid to new and existing customers.', checked: false },
  //       { id: 2, instruction: 'Valid to new customers only.', checked: false },
  //       { id: 3, instruction: 'NOT applicable to already discounted products and services.', checked: false },


  //     ],
  //     termCond: '',
  //     currinst: '',
  //     currentInstruction: [
  //       { id: 1, instruction: 'Simply navigate to their website or online store.', checked: false },
  //       { id: 2, instruction: 'You must register online for this discount.', checked: false },
  //       { id: 3, instruction: 'Install the vendor’s mobile app.', checked: false },
  //       { id: 4, instruction: 'Enter this code on the online checkout page before payment.', checked: false },
  //       { id: 5, instruction: 'Show this code to the teller in-store.', checked: false },
  //       { id: 6, instruction: 'Present to salesperson after receiving a quote.', checked: false },
  //       { id: 7, instruction: 'Call for an appointment.', checked: false },
  //       { id: 8, instruction: 'Email for an appointment.', checked: false },
  //       { id: 9, instruction: 'WhatsApp for appointment.', checked: false },

  //     ],
  //     locationDetailsPage: {
  //       locations: [],
  //       checkedId: [],
  //       onlineOnly: recievedCoupon.onlineOnly,
  //       callToActionButton: [
  //         { id: "navigateBtn", name: 'Navigate Button', checked: false },
  //         { id: "websiteBtn", name: 'Webiste Button', checked: false },
  //         { id: "callBtn", name: 'Call Button', checked: false },
  //         { id: "emailBtn", name: 'Email Button', checked: false },
  //       ]
  //     },
  //     validFor: `${couponState.couponDealOption.expirationOption} ${getExpirationOption(couponState.couponDealOption.expirationButton)}`
  //   };


  //   editInitialState.locationDetailsPage.callToActionButton.forEach(button => {

  //     switch (button.id) {
  //       case "navigateBtn":
  //         button.checked = recievedCoupon.navigateBtn && recievedCoupon.navigateBtn === "yes";
  //         break;
  //       case "websiteBtn":
  //         button.checked = recievedCoupon.websiteBtn && recievedCoupon.websiteBtn === "yes";
  //         break;
  //       case "callBtn":
  //         button.checked = recievedCoupon.callBtn && recievedCoupon.callBtn === "yes";
  //         break;
  //       case "emailBtn":
  //         button.checked = recievedCoupon.emailBtn && recievedCoupon.emailBtn === "yes";
  //         break;
  //       default:
  //         break;
  //     }
  //   })

  //   recievedCoupon.instructions?.split("|").forEach((instruction) => {

  //     const existingIndex = editInitialState.currentInstruction.findIndex((item) => item.instruction == instruction);

  //     if (existingIndex !== -1) {
  //       editInitialState.currentInstruction[existingIndex].checked = true;
  //     } else {
  //       editInitialState.currentInstruction.push({
  //         id: editInitialState.currentInstruction.length + 1,
  //         instruction: instruction,
  //         checked: true
  //       });
  //     }
  //   });

  //   recievedCoupon.terms?.split("|").forEach((terms) => {

  //     const existingIndex = editInitialState.currentTermsCondList.findIndex((item) => item.instruction == terms);

  //     if (existingIndex !== -1) {
  //       editInitialState.currentTermsCondList[existingIndex].checked = true;
  //     } else {
  //       editInitialState.currentTermsCondList.push({
  //         id: editInitialState.currentTermsCondList.length + 1,
  //         instruction: terms,
  //         checked: true
  //       });
  //     }
  //   });



  //   const getCurrentDealOption = (dealValue, dealType) => {

  //     switch (dealType) {

  //       case "PER":
  //         editInitialState.couponDealOption.selectedDealOptions = 1;
  //         editInitialState.couponDealOption.second.dealVal1 = dealValue;
  //         break;
  //       default:
  //         break;
  //     }

  //   }


  //   getCurrentDealOption(recievedCoupon.deal, recievedCoupon.dealUnit);
  //   setCouponState(editInitialState);
  // }

  return <CouponContextV2.Provider value={{ couponState, updateCouponState, clearState, editCouponState }}>{children}</CouponContextV2.Provider>

}