//react
import React, { useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";

//mui
import { Button, TextField, Switch, FormGroup, FormControlLabel, CircularProgress } from '@mui/material';

//context
import AuthContext from '../../../context/AuthContext';
import DrawerContext from '../../../context/DrawerContext';
import ModalContext from '../../../context/ModalContext';

//component
import TextInput from '../../../common/TextInput'


const ManageLocationDrawer = () => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  const [loading, setLoading] = useState(false)


  const [createBlogObj, setCreateBlogObj] = useState({ snippet: "", title: "", post: "", pageTitle: "", metaDescription: "", metaKeywords: "", isVideo: "no", videoUrl: "" });

  const submitCreateBlog = () => {

    setLoading(true)

    const { snippet, title, post, pageTitle, metaDescription, metaKeywords, isVideo, videoUrl } = createBlogObj;

    try {

      fetch(process.env.REACT_APP_API_URI + '/v3/admin/blog', {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ snippet, title, post, pageTitle, metaDescription, metaKeywords, isVideo, videoUrl })
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status === "success") {
          getAllBlogs(data.id)
        }
      })

    } catch (error) {
      console.log('error : ', error)
    }
  }

  const getAllBlogs = (id) => {

    try {

      fetch(`${process.env.REACT_APP_API_URI}/v3/blogs/${id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status === 'success') {
            setLoading(false)
            openUploadImageModal(id, data.data[0])
            drawerCtx.setDetails(null, data.data[0]);
            drawerCtx.closeDrawer();
          }
        }).catch(err => {
          console.error(err)
        });

    } catch (error) {
      console.log('error : ', error)
    }
  }

  const handleVideoBoolean = (e) => {
    if (e) {
      setCreateBlogObj((prev) => ({ ...prev, isVideo: 'yes' }))
    } else {
      setCreateBlogObj((prev) => ({ ...prev, isVideo: 'no' }))
    }
  }

  const openUploadImageModal = (id, blog) => {
    modalCtx.setDetails('upload-fixed-image', { aspectRatio: 1.6, origin: 'blog', id: id, blog: blog });
    modalCtx.openModal();
  }


  return (
    <div className="drawer-container">
      <div className="drawer">
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">Manage Locations</h1>
        </header>

        <section style={{ padding: '1rem', display:'flex', flexDirection:'column' , gap:'2rem' }}>
       
       <div className='locations-textfield'>
       <div className='textfields-container'>
        <TextField name="title" label="Location Title" value={createBlogObj?.title} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))}  />
        <TextField name="slug" label="Add Address" value={createBlogObj?.title} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))}  />
        <TextField name="contact-person" label="Phone" value={createBlogObj?.title} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))}  />
        <TextField name="email" label="Email" value={createBlogObj?.title} onChange={(e) => setCreateBlogObj((prev) => ({ ...prev, [e.target.name]: e.target.value }))}  />
        
        </div>
        <Button className='location-add-button'>Add</Button>
       </div>
        
        <div className='map-container'>

        </div>
        
        <div className="double-column-form aligned-left margin-top">
          <Button variant='outlined' disabled={!createBlogObj?.title.length} className='btn-primary' >Close</Button>
            {/* <button disabled={!createBlogObj?.title.length} className='btn-primary' onClick={(e) => submitCreateBlog()}>{loading ? <CircularProgress size={22} style={{color: "#fff"}} /> : "Save"}</button> */}
          </div>
        </section>
      </div>
    </div>
  )
}

export default ManageLocationDrawer;
