import React, { useEffect, useState, useContext } from 'react'

import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Delete, Edit, Star, StarBorder, StarRounded } from '@mui/icons-material';

import AuthContext from '../../context/AuthContext.js';
import ModalContext from '../../context/ModalContext.js';
import DrawerContext from '../../context/DrawerContext.js';

const Topics = () => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  };

  const [topics, setTopics] = useState([]);

  // updating status states 
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [updatingBlogStatus, setUpdatingBlogStatus] = useState(false);
  const [updatingStatusId, setUpdatingStatusId] = useState();
  const [updatingBlogStatusId, setUpdatingBlogStatusId] = useState();


  useEffect(() => {
    getAllTopics();
  }, []);

  const getAllTopics = () => {

    fetch(process.env.REACT_APP_API_URI + '/v3/topics', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }
    })
    .then(response => {
      // console.log(response);
      return response.json();
    }).then(data => {
      console.log('Success:', data);
      if (data.status == 'success') {
        setTopics(data?.data)
      }
    }).catch(err => console.error(err));

  }

  const updateStatusBlogs = async (blogId, status) => {

    setUpdatingBlogStatus(true);
    setUpdatingBlogStatusId(blogId);

    try {

      fetch(process.env.REACT_APP_API_URI + '/v3/admin/topics/' + blogId + '/updateFeaturedBlog', {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify({ status })
      })
        .then(response => {
          return response.json();
        }).then(data => {
          console.log(data);
          if (data.status == 'success') {
            getAllTopics();
            setUpdatingBlogStatus(false);
            setUpdatingBlogStatusId(null);
          }
        }).catch(err => console.error(err));

    } catch (err) {
      console.log(err);
    }
  }

  const getStatusClassForBlogs = (id) => {
    let topic = topics.find(obj => obj.id == id);
    if (topic && topic.featured_blogs == 'yes') {
      return 'author-tag published';
    } else {
      return 'author-tag';
    }
  }

  const getStatusClassForDevotionals = (id) => {
    let topic = topics.find(obj => obj.id == id);
    if (topic && topic.featured_devotionals == 'yes') {
      return 'author-tag published';
    } else {
      return 'author-tag';
    }
  }

  const openDeleteTopicModal = (id) => {
    modalCtx.setDetails('delete-topic', { id });
    modalCtx.openModal();
  }


  return (
    <div className="topics-page">

      <header className="template-header" >
        {/* <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Topics &nbsp; {loadingBlogs ? <CircularProgress size={22} /> : null}</h1> */}
        <h1 className='df-center' data-aos="fade-right" data-aos-delay="500">Topics</h1>
        {/* <div>
          <button className='btn-primary' data-aos="fade-right" data-aos-delay="400" onClick={() => { toggleDrawerHandler('create-blog', null) }}>Create New</button>
        </div> */}
      </header>

      <section className='template-section' >
        <div className='topics-table'>
          <TableContainer className='topics-table' component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead style={{ position: "sticky", top: "0", background: "#FFF", zIndex: "100" }}>
                <TableRow>
                  <TableCell align="left" style={{ fontWeight: 700, fontSize: '16px' }} >Topic</TableCell>
                  <TableCell align="right" style={{ fontWeight: 700, fontSize: '16px' }} >Featured for Blogs</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  topics?.length
                    ? topics?.map((topic, index) => (
                      <TableRow style={{ background: '#fff', boxShadow: "10px 3px 10px rgba(0, 0, 0, 0.0392156863)", borderRadius: "8px" }} key={topic.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell><span style={{ fontWeight: 500, fontSize: '16px' }} >{topic.title}</span></TableCell>
                        <TableCell align="right" className='topic-featured'>
                          <div style={{ padding: '0 55px' }} onClick={() => updateStatusBlogs(topic.id, topic.status)}>
                            {
                              (updatingBlogStatus && updatingBlogStatusId == topic.id) ? <CircularProgress size={20} /> : <StarRounded style={{ cursor: 'pointer' }} className={getStatusClassForBlogs(topic.id)} />
                            }
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <Edit style={{ cursor: 'pointer' }} onClick={() => toggleDrawerHandler('edit-topic', topic)} />
                          <Delete style={{ marginLeft: '1rem', cursor: 'pointer' }} onClick={(e) => openDeleteTopicModal(topic.id)} />
                        </TableCell>
                      </TableRow>
                    ))
                    : null
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </section>
    </div>
  )
}

export default Topics;
