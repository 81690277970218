import { Add, CheckBox } from '@mui/icons-material';
import { Button, Card, Checkbox, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../context/ModalContext';
import AuthContext from '../../../context/AuthContext';

export default function Location({ locationDetails, setLocationDetails, company, errors, setError }) {

  const authCtx = useContext(AuthContext);

  const modalCtx = useContext(ModalContext);

  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    fetchLocations();
  }, [])

  useEffect(() => {
    if (allSelected) {
      const allIDs = locationDetails.locations.map(lc => lc.id);
      setLocations(allIDs);
    }
    else{
      setLocations([])
    }
  }, [allSelected])

  const handleModal = (modal, data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, data);
  }

  const setLocations = (_locations) => {
    setError('location', false)
    setLocationDetails({ ...locationDetails, checkedId: _locations })
  }

  const setCallToActions = (_cta) => {
    setLocationDetails({ ...locationDetails, callToActionButton: _cta })
  }

  const toggleCTA = (_id) => {
    setError('cta', false)
    const currentIndex = locationDetails.callToActionButton.findIndex((item) => item.id == _id);
    const newList = [...locationDetails.callToActionButton];
    newList[currentIndex].checked = !newList[currentIndex].checked;
    setCallToActions(newList);
  }

  const toggleOnlineOnly = (_value) => {
    setError('cta', false)
    const newList = [...locationDetails.callToActionButton];
    newList.forEach(cta => cta.checked = false);
    setCallToActions(newList);
    setLocationDetails({ ...locationDetails, onlineOnly: _value })
  }

  const addNewLocation = (_location) => {
    if (_location) {
      let newList = [...locationDetails.locations, _location];
      var newCheckedList = [...locationDetails.checkedId, _location.id];

      var _locationDetails = {
        ...locationDetails,
        checkedId: newCheckedList,
        locations: newList,
      };

      setLocationDetails(_locationDetails)
      modalCtx.closeModal();
    }
  }

  const fetchLocations = () => {
    if (company?.id) {
      try {
        fetch(`${process.env.REACT_APP_API_URI}/v3/admin/companies/${company.id}/locations`, {
          method: "GET",
          headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token }

        }).then((res) => { return res.json() }).then((data) => {
          console.log(data);
          if (data.status == 'success') {
            setLocationDetails({ ...locationDetails, locations: [...data?.data] })
          } else {
            console.error(data);
          }
        })
      } catch (e) {
        console.log(e);
      }
    }
  }

  return (
    <div className='create-coupon-section-v2'>
      <div className='horizontal-row-card-container'>
        <Card className='card-container' sx={{ width: '60%' }}>
          <div className='full-width location-header'>
            <div className='title-subtitle-container'>
              <span className='title'>Locations</span>
              <span className='subtitle'>Select the locations for your coupon.</span>
            </div>
            <span className='checkbox-span'><Checkbox checked={allSelected} onChange={() => setAllSelected(as => !as)} sx={{
              '& .MuiSvgIcon-root': { fontSize: 20 }
            }} /> Check all locations</span>
          </div>

          {locationDetails.locations.length > 0 ? <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={64}></TableCell>
                  <TableCell width={200} >Title</TableCell>
                  <TableCell>Address</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {locationDetails.locations.map((row) => {
                  return <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell width={50} >
                      <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 50 } }} edge="start"
                        checked={locationDetails.checkedId.includes(row.id)}
                        tabIndex={-1}
                        disableRipple
                        onChange={() => {
                          let newList;
                          if (locationDetails.checkedId.includes(row.id)) {
                            newList = locationDetails.checkedId.filter((item) => item != row.id);
                          } else {
                            newList = [...locationDetails.checkedId, row.id];
                          }

                          setLocations(newList);
                        }} /></TableCell>
                    <TableCell width={200}>
                      {row.title}
                    </TableCell>
                    <TableCell component="th" >{row.address}</TableCell>

                  </TableRow>
                }
                )}
              </TableBody>
            </Table>
          </TableContainer> : <div>
            <span>Please add a Location First</span>
          </div>}

          {errors?.location?.show && <div className="coupon-error-text">{errors.location.message}</div>}


        </Card>
        <Card className='card-container center-card' sx={{ width: '40%' }}>
          <img src='https://admin.delivait.com/assets/img/location.png' />
          <span className='title'>Don't you have a registered Location?</span>
          <Button onClick={() => handleModal("add-location-modal", { addHandler: addNewLocation, company: company })} variant="outlined"><Add /> Add a New Location</Button>
        </Card>
      </div>

      <div className='horizontal-row-card-container'>
        <Card className='card-container' sx={{ width: '60%' }}>
          <span className='title'>Call to Action Buttons</span>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {locationDetails?.onlineOnly === "yes" ?
              <ListItem
                disablePadding
              >
                <ListItemButton role={undefined} onClick={() => toggleOnlineOnly("no")} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={true}
                      tabIndex={-1}
                      disableRipple
                      onChange={() => {
                        toggleOnlineOnly("no");
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={`Coupon is Only Available Online`} />
                </ListItemButton>
              </ListItem>
              : locationDetails.callToActionButton.map((value) => {
                const labelId = `checkbox-list-label-${value.id}`;
                return (
                  <ListItem
                    key={value.id}
                    secondaryAction={
                      <IconButton edge="end" aria-label="comments">
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={() => toggleCTA(value.id)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={value.checked}
                          tabIndex={-1}
                          disableRipple
                          // onChange={() => toggleCTA(value.id)}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={`${value.name}`} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </List>
          {errors?.cta?.show && <div className="coupon-error-text">{errors.cta.message}</div>}
          <Button onClick={() => {
            toggleOnlineOnly("yes");
          }} sx={{ backgroundColor: '#2b1de8', color: 'white' }} variant='filled'>Coupon is Only Available Online</Button>
        </Card>
      </div>
    </div>
  )
}
